import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { calculateAge } from "../../utils";

const defaultColDef = {
  sortable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  resizable: true,
  cellStyle: { fontSize: "12px" },
};

const AllLeadsTable = ({ data }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleCheckBoxSelection = e => (e.node.isSelected() ? removeLeadFromTable(e) : null);
  const removeLeadFromTable = e => {
    console.log(e.data);
    setTableData(tableData.filter(row => row.lead_id !== e.data.lead_id));
  };

  const EventDetails = ({ data }) => {
    if (data["event_date"] && data["start_time"] && data["end_time"]) {
      const formattedDate = `${moment(data["event_date"]).format("MMM D, YYYY")}, ${data["start_time"]} - ${data["end_time"]}`;
      const guestCount = `${data["guest_count"]} Guests`;
      return <MultiLineCell firstValue={formattedDate} secondValue={guestCount} />;
    } else {
      return <div></div>;
    }
  };

  const DateCreated = ({ data }) => {
    const formattedDate = moment(data["created_at"]).format("L");
    const age = calculateAge(data["created_at"]);
    return <MultiLineCell firstValue={formattedDate} secondValue={age} />;
  };

  const MultiLineCell = ({ firstValue, secondValue }) => {
    return (
      <div>
        <div>{firstValue || "Unassigned"}</div>
        <div class="pb-3 italic text-gray-400" style={{ fontSize: "11px", lineHeight: "0.25" }}>
          {secondValue}
        </div>
      </div>
    );
  };

  const LeadDetails = ({ data }) => {
    const fullName = `${data["first_name"]} ${data["last_name"]}`;
    return <MultiLineCell firstValue={fullName} secondValue={data["email_address"]} />;
  };

  const Duration = ({ value }) => {
    if (!value) return <div></div>;
    const minutesDiff = value / 1000 / 60;
    const hoursDiff = minutesDiff / 60;
    const daysDiff = hoursDiff / 24;
    return <div>{minutesDiff.toFixed(0)} mins</div>;
    // if (minutesDiff < 60) return <div>{minutesDiff.toFixed(0)} mins</div>;
    // else if (hoursDiff < 24) return <div>{hoursDiff.toFixed(0)} hrs</div>;
    // else return <div>{daysDiff.toFixed(0)} days</div>;
  };

  const [columnDefs] = useState([
    { field: "created_at", headerName: "Date Created", cellRenderer: row => <DateCreated data={row.data} />, filter: false, width: 150, checkboxSelection: true },
    { field: "owner.first_name", headerName: "Owner", width: 150, cellRenderer: ({ data }) => <MultiLineCell firstValue={data.owner ? data.owner.first_name : ""} secondValue={data.location} /> },
    { field: "status", headerName: "Status", width: 150 },
    { field: "time_to_assignment", headerName: "Time To Assignment", width: 150, cellRenderer: row => <Duration value={row.data.time_to_assignment} /> },
    { field: "time_assigned_to_contact", headerName: "Assigned To Contact", width: 150, cellRenderer: row => <Duration value={row.data.time_assigned_to_contact} /> },
    { field: "time_to_first_contact", headerName: "Time to First Contact", width: 150, cellRenderer: row => <Duration value={row.data.time_to_first_contact} /> },
    { field: "first_name", headerName: "Full Name", width: 250, cellRenderer: row => <LeadDetails data={row.data} /> },
    // { field: "event_date", headerName: "Event", width: 250, cellRenderer: row => <EventDetails data={row.data} />, filter: false, sortable: false, autoHeight: true },
    {
      field: "lead_id",
      headerName: "",
      width: 150,
      sortable: false,
      filter: false,
      cellClass: "h-full",
      cellRenderer: data => (
        <a href={`https://encorecatering.tripleseat.com/leads/${data.value}`} target="_blank" className="ml-3 inline-flex cursor-pointer items-center justify-center rounded-md border border-transparent bg-cyan-500 px-6 py-1 text-xs  font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-none sm:text-xs sm:leading-5">
          View
        </a>
      ),
    },
  ]);

  const gridConfig = {
    rowData: tableData,
    columnDefs: columnDefs,
    defaultColDef: defaultColDef,
    enableCellTextSelection: true,
    onRowSelected: handleCheckBoxSelection,
    rowHeight: 67,
  };

  return (
    <div className="ag-theme-alpine" style={{ height: "600px", width: "100%" }}>
      <AgGridReact {...gridConfig}></AgGridReact>
    </div>
  );
};

export default AllLeadsTable;
