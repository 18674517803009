import { startOfYear, endOfYear, addYears } from "date-fns";
import { createStaticRanges } from "react-date-range";

export const customStaticRanges = createStaticRanges([
  {
    label: "This Year",
    range: () => ({
      startDate: startOfYear(new Date()),
      endDate: endOfYear(new Date()),
    }),
  },
  {
    label: "Last Year",
    range: () => ({
      startDate: startOfYear(addYears(new Date(), -1)),
      endDate: endOfYear(addYears(new Date(), -1)),
    }),
  },
]);
