import React, { useCallback, useState, useEffect } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import ChartNumberOfDownloadsByMenu from "../components/MenuDownloads/ChartNumberOfDownloadsByMenu";
import MenuDownloadTimeSeries from "../components/MenuDownloads/MenuDownloadTimeSeries";
import DateRange from "../components/DateRangePicker/DateRange";
import { addDays, subMonths } from "date-fns";
import MenuDownloadsTable from "../components/MenuDownloads/MenuDownloadsTable";
import { getFormSubmissions } from "../actions/wordpress";
import axios from "../actions/axios";
const groupBy = require("lodash/groupBy");

const ResponsiveGridLayout = WidthProvider(Responsive);

const MenuDownloads = () => {
  const [primaryDateRange, setPrimaryDateRange] = useState({ startDate: subMonths(new Date(), 1), endDate: new Date(), key: "selection" });
  const [comparisonDateRange, setComparisonDateRange] = useState({ startDate: subMonths(new Date(), 2), endDate: subMonths(new Date(), 1), key: "selection" });

  const [data, setData] = useState({});

  const [locations, setLocations] = useState(["Encore Catering", "F+B Kosher"]);

  function handleLocationToggle(company) {
    if (locations.includes(company)) {
      setLocations(locations.filter(location => location !== company));
    }
    if (!locations.includes(company)) {
      setLocations([...locations, company]);
    }
  }

  const getCountByMenu = data => {
    const counts = data.reduce((res, download) => {
      const menu = download.menu.replace("Download", "").replace("Menu", "").trim();
      res[menu] = res[menu] ? res[menu] + 1 : 1;
      return res;
    }, {});
    return counts;
  };

  const fetchData = async () => {
    const primaryDataQuery = {
      submission_date: {
        $gte: primaryDateRange.startDate.toISOString(), // isodate
        $lt: primaryDateRange.endDate.toISOString(),
      },
    };
    const comparisonDataQuery = {
      submission_date: {
        $gte: comparisonDateRange.startDate.toISOString(),
        $lt: comparisonDateRange.endDate.toISOString(),
      },
    };
    const res = await Promise.all([axios.get(`/api/menudownload`, { params: primaryDataQuery }), axios.get(`/api/menudownload`, { params: comparisonDataQuery })]);
    const primaryData = res[0].data.filter(download => locations.includes(download.location));
    const comparisonData = res[1].data.filter(download => locations.includes(download.location));
    console.log({ primaryData, comparisonData });
    const primaryMenuCounts = getCountByMenu(primaryData);
    const comparisonMenuCounts = getCountByMenu(comparisonData);
    const primaryGroup = groupBy(primaryData, download => download.menu);
    const comparisonGroup = groupBy(comparisonData, download => download.menu);
    const menus = Object.keys(primaryGroup);

    setData({ primaryData, comparisonData, primaryMenuCounts, comparisonMenuCounts, primaryGroup, comparisonGroup, menus });
  };

  useEffect(() => {
    fetchData();
  }, [primaryDateRange, comparisonDateRange, locations.length]);

  console.log(data);

  const layouts = {
    lg: [
      { i: "a", x: 0, y: 0, w: 6, h: 3, minW: 5, minH: 2.5 },
      { i: "b", x: 7, y: 0, w: 6, h: 3 },
      { i: "c", x: 0, y: 4, w: 12, h: 6 },
    ],
  };

  //resize with preserving aspect ratio
  const handleResize = useCallback((l, oldLayoutItem, layoutItem, placeholder) => {
    const heightDiff = layoutItem.h - oldLayoutItem.h;
    const widthDiff = layoutItem.w - oldLayoutItem.w;
    const changeCoef = oldLayoutItem.w / oldLayoutItem.h;
    if (Math.abs(heightDiff) < Math.abs(widthDiff)) {
      layoutItem.h = layoutItem.w / changeCoef;
      placeholder.h = layoutItem.w / changeCoef;
    } else {
      layoutItem.w = layoutItem.h * changeCoef;
      placeholder.w = layoutItem.h * changeCoef;
    }
  }, []);

  return (
    <div>
      <div class="ml-2 flex space-x-2">
        <DateRange dateRange={primaryDateRange} setDateRange={setPrimaryDateRange} label="Period" />
        <DateRange dateRange={comparisonDateRange} setDateRange={setComparisonDateRange} label="Compare To" />
        <div className="flex items-end mb-2 space-x-2">
          <div onClick={() => handleLocationToggle("Encore Catering")} data-state={locations.includes("Encore Catering") ? "active" : "inactive"} className="px-4 py-2 rounded-md border border-gray-300 cursor-pointer hover:border-white text-white  data-[state=active]:!bg-gray-300 data-[state=active]:!text-gray-900">
            Encore
          </div>
          <div onClick={() => handleLocationToggle("F+B Kosher")} data-state={locations.includes("F+B Kosher") ? "active" : "inactive"} className="px-4 py-2 rounded-md border border-gray-300 hover:border-white cursor-pointer text-white  data-[state=active]:!bg-gray-300 data-[state=active]:!text-gray-900">
            F+B
          </div>
        </div>
      </div>

      <ResponsiveGridLayout className="layout text-white" draggableHandle=".my-drag-handle" onResize={handleResize} layouts={layouts} breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }} cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}>
        <div className="rounded-md border-2 border-gray-400 p-4" key="a">
          <ChartNumberOfDownloadsByMenu data={data} />
        </div>
        <div className="rounded-md border-2 border-gray-400 p-4" key="b">
          <MenuDownloadTimeSeries data={data} />
        </div>
        <div className="rounded-md border-2 border-gray-400" key="c">
          <MenuDownloadsTable data={data.primaryData} />
        </div>
      </ResponsiveGridLayout>
    </div>
  );
};

export default MenuDownloads;
