import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import EncoreLogo from "../components/EncoreLogo/EncoreLogo";
import SearchInput from "../components/Search/SearchInput";
import Bookmarks from "../components/Bookmarks/Bookmarks";
import Tags from "../components/TagSelector/Tags";
import BookmarkSearch from "../components/Bookmarks/BookmarkSearch";

const Home = () => {
  const [tagFilter, setTagFilter] = useState([]);

  return (
    <div className="p-8">
      <EncoreLogo />
      <SearchInput />
      <Tags tagFilter={tagFilter} setTagFilter={setTagFilter} />
      <BookmarkSearch />
      <Bookmarks tagFilter={tagFilter} />
    </div>
  );
};

export default Home;
