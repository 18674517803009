import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../../actions/axios";
import { Link } from "react-router-dom";

const Article = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState("");

  const fetchArticle = async () => {
    const res = await axios.post(`/api/support/article`, { id: articleId });
    console.log(res.data);
    const article = res.data.article;
    article.description = article.description.replace(/<p> <\/p>/g, "").replace(/<p><br><\/p>/g, "");
    console.log(article);
    setArticle(article);
  };

  useEffect(() => {
    fetchArticle();
  }, [articleId]);

  return (
    <div class="flex justify-center">
      <div class="my-6 max-w-[900px] rounded-md bg-white p-20 shadow-lg">
        <div class="mb-6 text-2xl font-bold">{article.title}</div>
        <article class="prose m-auto mb-6" dangerouslySetInnerHTML={{ __html: article.description }}></article>
        <Link class="mt-6 flex items-center" to="/home">
          <svg class="mr-1 w-6 text-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              fill="currentColor"
              d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM384 288H205.3l49.38 49.38c12.5 12.5 12.5 32.75 0 45.25s-32.75 12.5-45.25 0L105.4 278.6C97.4 270.7 96 260.9 96 256c0-4.883 1.391-14.66 9.398-22.65l103.1-103.1c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L205.3 224H384c17.69 0 32 14.33 32 32S401.7 288 384 288z"
            />
          </svg>
          Back to Dashboard
        </Link>
      </div>
    </div>
  );
};

export default Article;
