import React from "react";

const Tag = ({ tag, tagFilter, onClick }) => {
  const active = tagFilter.includes(tag._id);
  const classNames = `hover-ring my-2 mr-2 ml-2 flex min-w-[100px] flex-none cursor-pointer items-center justify-center space-x-4 rounded-full px-6 py-2 text-center text-xs ${
    active ? "bg-gray-300 text-gray-900 active-ring" : "bg-gray-800 text-gray-300 hover:text-white"
  }`;
  return (
    <div id={tag._id} onClick={onClick} class={classNames}>
      {tag.name}
    </div>
  );
};

export default Tag;
