const cheerio = require("cheerio");

export async function getGuestThreadIdByLead(leadId) {
  const res = await this.bget(`/leads/${leadId}`);
  const $ = cheerio.load(res);
  const threadId = $($("#tab-discussion").find(".discussion_grid")[1]).data("thread_id");
  return threadId;
}

export async function getGuestDiscussionsByLead(leadId) {
  const threadId = await this.getGuestThreadIdByLead(leadId);
  if (!threadId) return [];
  const res = await this.bget(`/discussions/${threadId}/comments`);
  const $ = cheerio.load(res.content);
  const commentRows = $(".discussion_comment");
  const comments = [];
  for (const el of commentRows.toArray()) {
    const commentId = $(el).data("comment_id");
    const user = $(el).find('[data-cy="comment_user"]').text();
    let time = $(el).find('[data-cy="comment_date_time"]').text().replace(/\n/g, " ").trim();
    time = time && new Date(time);
    const comment = { id: commentId, user: user, time: time };
    comments.push(comment);
  }
  return comments;
}

export async function getGuestDiscussionsAsCommunications(leadId) {
  const discussions = await this.getGuestDiscussionsByLead(leadId);
  const communications = discussions.map(discussion => {
    return {
      communication_id: String(discussion.id),
      date: discussion.time,
      communication_type: "discussion",
      method: "email",
      user: discussion.user,
    };
  });
  return communications;
}

export async function createDiscussion(leadId, body) {
  const threadId = await this.getGuestThreadIdByLead(leadId);

  const payload = {
    discussion_comment: { body: body, user_recipient_ids: "user_76044" },
    signature_enabled: 1,
    "guest_recipients[]": "6342413",
  };
  const res = await this.bpost(`/discussions/${threadId}/comments`);
  return res;
}
