import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchBookmarks, setBookmark, resetBookmark } from "../../actions/bookmark";
import BookmarkModal from "./BookmarkModal/BookmarkModal";
import Bookmark from "./Bookmark";
import NewBookmarkTile from "./NewBookmarkTile";
import { chunk } from "../../utils";
import { ListManager } from "react-beautiful-dnd-grid";
import { upsertUser } from "../../actions/user";
import { setAlert } from "../../actions/alert";
import useWindowDimensions from "../Hooks/useWindowDimensions";

const Bookmarks = ({ currentUser, dashboardUser, bookmarks, tagFilter, setBookmark, resetBookmark, fetchBookmarks, upsertUser, setAlert }) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [filteredBookmarks, setFilteredBookmarks] = useState([[]]);
  const [page, setPage] = useState(0);

  const [itemsPerRow, setItemsPerRow] = useState(5);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerRow * 2 - 1);

  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (!bookmarks.length) fetchBookmarks();
  }, [fetchBookmarks, bookmarks]);

  useEffect(() => {
    if (width > 1850) setItemsPerRow(8);
    else if (width > 1650) setItemsPerRow(7);
    else if (width > 1450) setItemsPerRow(6);
    else if (width > 1250) setItemsPerRow(5);
    else if (width > 1075) setItemsPerRow(4);
    else setItemsPerRow(3);
  }, [width]);

  useEffect(() => {
    setItemsPerPage(bookmarks.length);
  }, [bookmarks, itemsPerRow]);

  useEffect(() => {
    // show only the bookmarks that contain all of the ids in the list of tagFilters
    const filtered = bookmarks.filter(bookmark => {
      const bookmarkTags = bookmark.tags.map(tag => tag._id);
      return tagFilter.every(tagId => bookmarkTags.includes(tagId));
    });
    const filteredAndPaginated = filtered.length && itemsPerPage ? chunk(filtered, itemsPerPage) : [];
    if (filteredAndPaginated.length) setFilteredBookmarks(filteredAndPaginated);
    else setFilteredBookmarks([[]]);
  }, [bookmarks, tagFilter, itemsPerPage]);

  const decrementPage = () => setPage(page - 1);
  const incrementPage = () => setPage(page + 1);

  const handleViewAll = () => {
    setPage(0);
    itemsPerPage === bookmarks.length ? setItemsPerPage(itemsPerRow * 2 - 1) : setItemsPerPage(bookmarks.length);
  };

  const handleDragEnd = (sourceIndex, destinationIndex) => {
    if (tagFilter.length) return setAlert("error", "Please remove all filters before attempting to reorder bookmarks...");
    if (sourceIndex === 0) return; // disable ability to move the "Add Bookmark" tile

    // This is strictly to visually show the update faster without waiting for DB to be updated
    sourceIndex = sourceIndex - 1;
    destinationIndex = destinationIndex - 1;

    const updatedFiltered = filteredBookmarks[page].filter((b, i) => i !== sourceIndex);
    updatedFiltered.splice(destinationIndex, 0, filteredBookmarks[page][sourceIndex]);
    filteredBookmarks[page] = updatedFiltered;
    setFilteredBookmarks([...filteredBookmarks]);

    // // Update the bookmarks array for the user in the DB

    sourceIndex = itemsPerPage * page + sourceIndex;
    destinationIndex = itemsPerPage * page + destinationIndex;

    const updatedBookmarks = bookmarks.filter((b, i) => i !== sourceIndex);
    updatedBookmarks.splice(destinationIndex, 0, bookmarks[sourceIndex]);

    upsertUser({ _id: dashboardUser._id, bookmarks: updatedBookmarks }, false);
  };

  const renderBookmark = bookmark => {
    if (bookmark._id) {
      return (
        <div class="">
          <Bookmark user={currentUser} setBookmark={setBookmark} setModalVisibility={setModalVisibility} bookmark={bookmark} key={bookmark._id} />
        </div>
      );
    } else {
      return (
        <div class="">
          <NewBookmarkTile resetBookmark={resetBookmark} setModalVisibility={setModalVisibility} />
        </div>
      );
    }
  };

  return (
    <>
      <BookmarkModal isModalVisible={isModalVisible} setModalVisibility={setModalVisibility} />
      <div class={`bookmarks-container col-${itemsPerRow}`}>
        <ListManager
          items={[{ id: itemsPerRow, name: "NewBookmarkTile" }, ...filteredBookmarks[page]]}
          direction="horizontal"
          maxItems={itemsPerRow}
          render={renderBookmark}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div class="mt-6 flex items-center justify-between">
        <div className="text-sm text-gray-400">
          <span>
            Showing <b>{itemsPerPage * page + 1}</b> to <b>{itemsPerPage * page + filteredBookmarks[page].length + (page + 1) * 1}</b> of{" "}
            {filteredBookmarks.flat().length + filteredBookmarks.length * 1} results
          </span>
          <span class="mx-4"> | </span>
          <span onClick={handleViewAll} class="cursor-pointer font-medium hover:text-gray-200 hover:underline">
            {itemsPerPage === bookmarks.length ? "View Pages" : "View All"}
          </span>
        </div>
        <div className="flex flex-1 justify-between sm:justify-end">
          {page > 0 && (
            <button
              onClick={decrementPage}
              type="button"
              className="ml-3 inline-flex items-center rounded-md  bg-gray-900 px-4 py-2 text-sm font-medium text-gray-400 shadow-sm transition  hover:bg-gray-800 hover:text-gray-100 focus:outline-none"
            >
              Previous
            </button>
          )}
          {page < filteredBookmarks.length - 1 && (
            <button
              onClick={incrementPage}
              type="button"
              className="ml-3 inline-flex items-center rounded-md  bg-gray-900 px-4 py-2 text-sm font-medium text-gray-400 shadow-sm transition  hover:bg-gray-800 hover:text-gray-100 focus:outline-none"
            >
              Next
            </button>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  bookmarks: state.user.user.value === "All Employees" ? state.bookmark.bookmarks : state.user.user.bookmarks || [],
  currentUser: state.auth.user,
  dashboardUser: state.user.user,
});

export default connect(mapStateToProps, { fetchBookmarks, setBookmark, resetBookmark, upsertUser, setAlert })(Bookmarks);
