import React, { useState, useEffect } from "react";
import { Tripleseat } from "../actions/tripleseat/main";
const cheerio = require("cheerio");

const Playground = () => {
  const [value, setValue] = useState("hey");

  const fetchData = async () => {
    const res = await Tripleseat.getGuestDiscussionsByLead("15965878");
    console.log(res);

    // const res = await getFormSubmissionsExport("Encore Catering", "2022-04-02", "2022-05-02");

    // const res = await Tripleseat.getRecord("leads", "15965526");
    // const leadId = "15965878";
    // const comments = await Tripleseat.getGuestDiscussionsByLead(leadId);
    // console.log(comments);
    // const tasks = [];
    // const taskRows = $(".task_row").toArray();
    // for (const el of taskRows) {
    //   const taskId = $(el).prop("id").replace("task_", "");
    //   const task = await this.getTaskDetails(taskId, leadId);
    //   console.log(task);
    //   tasks.push(task);
    // }
    // return tasks;
    // const ref = {};
    // $("#LeadSource")
    //   .find(".dropdown_section .custom_field")
    //   .each(function () {
    //     const name = $(this).find("input").val();
    //     const id = $(this).next("input").val();
    //     ref[name] = id;
    //   });
    // console.log(ref);
    // const leads = await Tripleseat.createLead({
    //   location: "Encore Catering",
    //   first_name: "Michael",
    //   last_name: "Test",
    //   email_address: "michaeltest434235358932434989@encorecatering.com",
    // });
    // console.log(leads);
    // const lead = await Tripleseat.updateLead("15942173", {
    //   additional_information: "hello",
    // });
    // console.log(lead);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div class="p-16">
      <div class="mb-4 text-lg text-white">Playground</div>
      <div class="text-xs text-white">{value}</div>
    </div>
  );
};

export default Playground;
