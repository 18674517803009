import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "../../../actions/axios";
import { upsertBookmark, setBookmark, deleteBookmark, resetBookmark } from "../../../actions/bookmark";
import { removeBookmarkFromUser } from "../../../actions/user";
import BookmarkIcon from "./BookmarkIcon";
import BookmarkModalForm from "./BookmarkModalForm";
import BookmarkModalIconSelector from "./BookmarkModalIconSelector";

const BookmarkModal = ({ bookmark, user, isModalVisible, setModalVisibility, upsertBookmark, setBookmark, resetBookmark, deleteBookmark, removeBookmarkFromUser }) => {
  const [error, setError] = useState("");
  const [page, setPage] = useState("form"); // form || icon-editor

  const handleChange = e => {
    e = e.target ? e.target : e;
    setBookmark({
      ...bookmark,
      [e.id]: e.value,
    });
  };

  useEffect(() => {
    if (isModalVisible) document.body.classList.add("overflow-hidden");
    else document.body.classList.remove("overflow-hidden");
  }, [isModalVisible]);

  const handleSubmit = async () => {
    if (!bookmark.name || !bookmark.url) return setError("You must complete all the fields!");
    const res = await upsertBookmark(bookmark);
    console.log(res);
    if (!res._id) return setError(res.error || "Error! Unable to create the bookmark, Please try again.");
    resetBookmark();
    setModalVisibility(false);
  };

  const fetchBookmarkIcon = async url => {
    // "https://res.cloudinary.com/encorecatering/image/upload/v1644181406/Generic_tsfh3p.ico";
    if (!url || bookmark.icon) return bookmark.icon;
    const faviconResponse = await axios.post("/api/favicon", { url });
    console.log(faviconResponse);
    const icon = faviconResponse.data.href;
    return icon;
  };

  const handleUrlChange = async () => {
    const url = !bookmark.url || bookmark.url.startsWith("http") ? bookmark.url : `https://${bookmark.url}`;
    const icon = await fetchBookmarkIcon(url);
    setBookmark({ ...bookmark, url, icon });
  };

  const handleDelete = () => {
    deleteBookmark(bookmark._id);
    setModalVisibility(false);
  };

  const handleClose = () => {
    setError("");
    setPage("form");
    setModalVisibility(false);
  };

  const handleRemoveUser = user => {
    // if the bookmark exists in the db and the bookmark has already been saved to the user then we need to update the db

    removeBookmarkFromUser(user, bookmark);

    // console.log(user.bookmarks);
    // if (bookmark._id && user.bookmarks.find(b => b._id === bookmark._id)) {
    //   removeBookmarkFromUser(user, bookmark);
    // }
  };

  const handleEditIcon = () => setPage("icon-editor");
  const handleResetIcon = () => {
    handleChange({ id: "icon", value: null });
    setPage("form");
  };

  return (
    <>
      {isModalVisible && (
        <div class="fixed inset-x-0 bottom-0 z-10 p-10 transition duration-300 sm:inset-0 sm:flex sm:items-center sm:justify-center sm:p-0">
          <div onClick={handleClose} class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-900 opacity-80"></div>
          </div>
          <div class="h-[730px] transform overflow-y-scroll rounded-lg border-2 border-gray-400 bg-gray-900 py-14 px-14 shadow-xl shadow-gray-800 transition-all sm:w-full sm:max-w-lg">
            {page === "icon-editor" && (
              <p onClick={() => setPage("form")} class="absolute top-5 cursor-pointer text-gray-400 hover:text-white">
                {" "}
                &lt; Back
              </p>
            )}
            <BookmarkIcon icon={bookmark.icon} handleEditIcon={handleEditIcon} handleResetIcon={handleResetIcon} page={page} />
            {page === "form" && <BookmarkModalForm bookmark={bookmark} user={user} error={error} handleSubmit={handleSubmit} handleChange={handleChange} handleDelete={handleDelete} handleRemoveUser={handleRemoveUser} handleUrlChange={handleUrlChange} />}
            {page === "icon-editor" && <BookmarkModalIconSelector handleChange={handleChange} setPage={setPage} />}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  bookmark: state.bookmark.bookmark,
});

export default connect(mapStateToProps, { upsertBookmark, setBookmark, deleteBookmark, resetBookmark, removeBookmarkFromUser })(BookmarkModal);
