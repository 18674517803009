import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select/creatable";
import { fetchUsers } from "../../actions/user";

const UsersMultiSelect = ({ id, value, onChange, handleRemove, users, currentUser, autoFetch = "true", fetchUsers }) => {
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  console.log({ value, users });

  useEffect(() => {
    setSelectedValues(value.filter(user => user._id !== currentUser._id));
  }, [value, currentUser]);

  useEffect(() => {
    console.log("setting options with updated users...");
    if (!users.length && autoFetch) fetchUsers();
    else setOptions([...users.filter(user => user._id !== currentUser._id)]);
  }, [fetchUsers, users, currentUser]);

  // useEffect(() => {
  //   const selectedAllEmployees = value.find(user => user.value === "All Employees");
  //   setSelectedValues(selectedAllEmployees ? [selectedAllEmployees] : value);
  // }, [value]);

  const styles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      outline: "none",
      boxShadow: "none",
      borderColor: isFocused ? "#fff" : "transparent",
      ":hover": { borderColor: "#fff" },
      borderRadius: "0.5rem",
      borderWidth: "2px",
      backgroundColor: "rgb(31 41 55)",
      fontSize: "1rem",
      fontWeight: "500",
      color: "white",
    }),
    multiValueRemove: styles => ({
      ...styles,
      ":hover": {
        backgroundColor: "lightgray",
        color: "black",
      },
    }),
    indicatorSeparator: styles => ({ display: "none" }),
    input: styles => ({ ...styles, color: "white", fontSize: "1rem", padding: "0.5rem", paddingLeft: "0", margin: "0" }),
    valueContainer: styles => ({ ...styles, padding: "0.25rem", margin: "0", paddingLeft: "1rem" }),
    singleValue: styles => ({ ...styles, color: "white", fontSize: "1rem" }),
    multiValue: styles => ({ ...styles, backgroundColor: "rgb(107 114 128)", marginLeft: "0", marginRight: "0.5rem", borderRadius: "0.5rem", color: "white", fontSize: "1rem" }),
    multiValueLabel: styles => ({ ...styles, color: "white" }),
    placeholder: styles => ({ ...styles, fontSize: "1rem", color: "rgb(107 114 128)", paddingLeft: "0" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "0.75rem",
      borderRadius: "0.25rem",
      backgroundColor: isFocused ? "rgb(107 114 128)" : "transparent",
      ":hover": {
        backgroundColor: "rgb(107 114 128)",
      },
    }),
    menu: styles => ({ ...styles, zIndex: "50", backgroundColor: "rgb(31 41 55)", color: "white", borderRadius: "0.5rem" }),
  };

  const handleChange = (values = [], event) => {
    if (event.action === "remove-value") {
      const user = users.find(user => user._id === event.removedValue._id); // necessary because we need the populated bookmarks array
      handleRemove(user);
    }
    const selectedAllEmployees = values.find(user => user.value === "All Employees");
    setOptions(selectedAllEmployees ? [selectedAllEmployees] : users);
    onChange({
      target: {
        id: id,
        value: selectedAllEmployees ? [selectedAllEmployees] : values,
      },
    });
  };

  return <Select defaultValue={[]} value={selectedValues} placeholder="Share with users..." onChange={handleChange} options={options} styles={styles} isMulti isClearable={false} />;
};

const mapStateToProps = state => ({
  users: state.user.users,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, { fetchUsers })(UsersMultiSelect);
