import { SET_LEAD, SET_LEADS, SET_LEADS_BY_DATERANGE, RESET_LEAD, DELETE_LEAD } from "./types";
import { setAlert } from "./alert";
import { Tripleseat } from "./tripleseat/main";
import axios from "./axios";

export const fetchLeads = filter => async dispatch => {
  try {
    const res = await axios.get("/api/lead", { params: filter });
    dispatch({
      type: SET_LEADS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const fetchLeadsByDateRange = (start, end) => async dispatch => {
  const query = {
    created_at: { $gte: start.toISOString(), $lt: end.toISOString() },
  };

  try {
    const res = await axios.get("/api/lead", { params: query });
    dispatch({
      type: SET_LEADS_BY_DATERANGE,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const refreshLeadOwnerAssignments = isDateRange => async (dispatch, getState) => {
  try {
    const leads = isDateRange ? getState().lead.leadsByDateRange : getState().lead.leads;

    const updatedLeads = await Tripleseat.updateLeadOwnerAssignments();

    const obj = updatedLeads.reduce((res, lead) => {
      res[lead._id] = lead;
      return res;
    }, {});

    const payload = leads.map(lead => (obj[lead._id] ? obj[lead._id] : lead));

    dispatch({
      type: isDateRange ? SET_LEADS_BY_DATERANGE : SET_LEADS,
      payload: payload,
    });
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const fetchLead = filter => async dispatch => {
  try {
    const res = await axios.get("/api/lead", { params: filter });
    dispatch({
      type: SET_LEAD,
      payload: res.data[0],
    });
    return res.data[0];
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const fetchLeadById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/lead/${id}`);
    dispatch({
      type: SET_LEAD,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const setLead = lead => async dispatch => {
  dispatch({
    type: SET_LEAD,
    payload: lead,
  });
};

export const setLeads = leads => async dispatch => {
  dispatch({
    type: SET_LEADS,
    payload: leads,
  });
};

export const resetLead = () => async dispatch => {
  dispatch({
    type: RESET_LEAD,
  });
};

export const upsertLead =
  (lead, msg = false) =>
  async dispatch => {
    try {
      const res = await axios.post(`/api/lead`, lead);
      dispatch({
        type: SET_LEAD,
        payload: res.data,
      });
      if (msg) dispatch(setAlert("success", "Success! The changes were successfully saved."));
      return res.data;
    } catch (err) {
      if (err.response) dispatch(setAlert("error", err.response.data.msg));
    }
  };

export const upsertLeads = leads => async dispatch => {
  try {
    let res = await Promise.all(leads.map(async lead => await axios.post(`/api/lead`, lead)));
    let data = res.map(r => r.data);
    dispatch({
      type: SET_LEADS,
      payload: data,
    });
    dispatch(setAlert("success", "Success! The changes were successfully saved."));
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const deleteLead = id => async dispatch => {
  try {
    await axios.delete(`/api/lead/${id}`);
    dispatch({
      type: DELETE_LEAD,
      payload: { id },
    });
    dispatch(setAlert("success", "Successfully deleted the lead"));
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

/**
 * Non-Core Functions
 */

/**
 * This function will run when the leads table is opened and will fetch any new lead owner assignments for the day and update the database to ensure the data viewed is in sync with Tripleseat
 */
export const updateLeadOwnerAssignments = () => async dispatch => {
  try {
    const assignments = await Tripleseat.getLeadOwnerAssignments();
    for (const assignment of assignments) {
      const { created_at, lead: lead_id, message } = assignment;
      const res = await axios.get("/api/lead", { params: { lead_id } });
      const lead = res.data[0];
      if (!lead || lead.assigned_at) continue;

      const resp = await axios.post(`/api/lead`, {
        ...lead,
        owner: message.match(/re-assigned from .* to (.*)/)[1],
        assigned_at: new Date(created_at),
      });

      dispatch({
        type: SET_LEAD,
        payload: resp.data,
      });
    }
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};
