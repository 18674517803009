import React, { useEffect } from "react";
import { connect } from "react-redux";
import Tag from "./Tag";
import { fetchTags } from "../../actions/tag";

const Tags = ({ tags, tagFilter, setTagFilter, fetchTags }) => {
  useEffect(() => {
    if (!tags.length) fetchTags();
  }, [tags, fetchTags]);

  console.log({ tags, tagFilter });

  // const tags = [
  //   "favourites",
  //   "ecommerce",
  //   "accounting",
  //   "tripleseat",
  //   "office365",
  //   "kitchen",
  //   "coordinators",
  //   "oswalds",
  //   "fbkosher",
  //   "encore",
  //   "ecommerce",
  //   "accounting",
  //   "tripleseat",
  //   "office365",
  //   "kitchen",
  // ];

  const handleClick = e => {
    const tagId = e.target.id;
    console.log(tagFilter.includes(tagId));
    if (tagFilter.includes(tagId)) setTagFilter(tagFilter.filter(id => id != tagId));
    else setTagFilter([...tagFilter, tagId]);
  };

  return (
    <div class="scrollable-element no-scroll my-6 flex touch-auto select-none overflow-x-auto 2xl:flex 2xl:justify-center">
      {tags.map(tag => (
        <Tag key={tag._id} tagFilter={tagFilter} tag={tag} onClick={handleClick} />
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  tags: state.tag.tags,
});

export default connect(mapStateToProps, { fetchTags })(Tags);
