import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "../../actions/axios";
import { Tripleseat } from "../../actions/tripleseat/main";
import PrimaryButton from "../Elements/PrimaryButton";

const CreateLeadButton = ({ rowData }) => {
  const [leadId, setLeadId] = useState(rowData.data.lead_id);
  const [loading, setLoading] = useState(false);

  const handleClick = async e => {
    setLoading(true);
    const { first_name, last_name, company, email, menu } = rowData.data;
    const leadSources = await Tripleseat.getLeadSources();
    const lead = await Tripleseat.createLead({
      location: company,
      first_name: first_name,
      last_name: last_name,
      email_address: email,
      lead_source_id: leadSources[menu || "Dashboard"],
    });
    setLeadId(lead.id);
    axios.post("/api/menudownload", { ...rowData.data, lead_id: lead.id });
    setLoading(false);
  };

  return (
    <p>
      {!leadId && (
        <PrimaryButton loading={loading} onClick={handleClick}>
          Create
        </PrimaryButton>
      )}
      {leadId && (
        <a target="_blank" href={`https://encorecatering.tripleseat.com/leads/${leadId}/edit`} class="ml-3 inline-flex w-full cursor-pointer items-center justify-center rounded-md border border-transparent bg-gray-900 px-3 py-2 text-sm  font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-700 focus:outline-none sm:text-sm sm:leading-5">
          View
        </a>
      )}
    </p>
  );
};

export default connect(null, {})(CreateLeadButton);
