import React from "react";

const Input = ({ label, placeholder, name, id, value, onChange, onBlur, classes = {}, autoFocus }) => {
  return (
    <div className={classes.root}>
      {label && (
        <label for={id} className={`block text-sm font-medium leading-5 text-gray-400 ${classes.label}`}>
          {label}
        </label>
      )}
      <input
        onBlur={onBlur}
        autoFocus={autoFocus}
        autoComplete={name}
        placeholder={placeholder}
        id={id}
        name={name}
        onChange={onChange}
        value={value || ""}
        className={`text-md w-full rounded-lg border-2 border-transparent bg-gray-800 p-2 pl-4 font-medium text-white placeholder-gray-500 transition duration-200 hover:border-white focus:border-white focus:outline-none ${classes.input}`}
      />
    </div>
  );
};

export default Input;
