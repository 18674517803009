import React from "react";
import { Chart as ChartJS, TimeScale, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";

ChartJS.register(CategoryScale, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      labels: {
        color: "white",
      },
    },
    title: {
      display: true,
      text: "Number of Downloads by Menu",
      color: "white",
    },
  },
  scales: {
    x: {
      type: "time",
      time: {
        displayFormats: {
          // quarter: "MMM YYYY",
        },
        // tooltipFormat: {},
      },
      distribution: "linear",
      ticks: { color: "white" },
      grid: { color: "transparent" },
    },
    y: {
      beginAtZero: true,
      ticks: { color: "white" },
      grid: { color: "#333" },
    },
  },
};

export function LineChart({ primaryData, comparisonData }) {
  console.log({ primaryData });
  const chartData = {
    datasets: [
      {
        label: "Period",
        data: primaryData,
        backgroundColor: "rgba(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.8)",
        color: "#fff",
      },
      // {
      //   label: "Comparison",
      //   data: comparisonData,
      //   backgroundColor: "rgba(53, 162, 235)",
      //   borderColor: "rgba(53, 162, 235, 0.8)",
      //   color: "#fff",
      // },
    ],
  };

  return <Line options={options} data={chartData} />;
}

export default LineChart;
