import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
const _ = require("lodash");

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        color: "white",
      },
    },
    title: {
      display: true,
      text: "Number of Leads by Coordinator",
      color: "white",
    },
  },
};

function LeadOwnerPieChart({ leadsWithOwner }) {
  const [data, setData] = useState({
    labels: [],
    values: [],
  });

  useEffect(() => {
    const ownerCounts = _.countBy(leadsWithOwner, lead => lead.owner.first_name);
    setData({
      labels: Object.keys(ownerCounts),
      values: Object.values(ownerCounts),
    });
  }, [leadsWithOwner]);

  const chartData = {
    labels: data.labels,
    datasets: [
      {
        label: "# of Votes",
        data: data.values,
        backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
        borderWidth: 1,
      },
    ],
  };

  return <Pie options={options} data={chartData} />;
}

export default LeadOwnerPieChart;
