import React, { useState } from "react";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import { customStaticRanges } from "./customStaticRanges";

const DateRange = ({ dateRange, setDateRange, label }) => {
  const [open, setOpen] = useState(false);

  const handleChange = ({ selection }) => setDateRange(selection);

  return (
    <div class="flex flex-col">
      <label className="mb-1 block text-sm font-medium leading-5 text-gray-400">{label}</label>
      <input
        onFocus={() => setOpen(true)}
        placeholder="Select a date range"
        readOnly
        value={dateRange ? `${dateRange.startDate.toLocaleDateString()} - ${dateRange.endDate.toLocaleDateString()}` : ""}
        className={`mb-2 w-[300px] rounded-lg border-2 bg-gray-800 p-2 pl-4 text-sm font-medium text-white placeholder-gray-500 transition duration-200 ${
          open ? "border-white" : "border-transparent"
        }  focus:outline-none`}
      />
      <div class={`z-30 transition ${open ? "visible opacity-100" : "invisible opacity-0"} `}>
        <div onClick={() => setOpen(false)} class="fixed inset-0 z-10 h-screen w-screen"></div>
        <div class="absolute z-40">
          <DateRangePicker
            className="relative"
            rangeColors={["#111827"]}
            showDateDisplay={false}
            onChange={handleChange}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[dateRange]}
            direction="horizontal"
            inputRanges={[]}
            staticRanges={[...defaultStaticRanges, ...customStaticRanges]}
          />
          <div className="rounded-b-lg bg-white text-right">
            <button onClick={() => setOpen(false)} class="m-2 cursor-pointer rounded-md bg-gray-800 py-2 px-6 text-sm text-white hover:bg-gray-700">
              Finished
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRange;
