import React from "react";
import { Edit, Trash } from "../../Icons";

const BookmarkIcon = ({ icon, handleEditIcon, handleResetIcon, page }) => {
  return (
    <div class="relative mb-8 flex justify-center">
      <img class="h-16 w-16 rounded-md" alt="bookmark icon" src={icon || "https://res.cloudinary.com/encorecatering/image/upload/v1644181406/Generic_tsfh3p.ico"} />
      <div class="absolute top-0 flex h-full w-full cursor-pointer items-center justify-center text-white transition duration-300 hover:bg-gray-900 hover:bg-opacity-75">
        {page !== "icon-editor" && (
          <div onClick={handleEditIcon} class="flex h-full w-full items-center justify-center opacity-0 transition hover:opacity-100">
            <Edit className="w-4" />
            <div class="ml-1 text-sm">Edit Icon</div>
          </div>
        )}
        {page === "icon-editor" && (
          <div onClick={handleResetIcon} class="flex h-full w-full items-center justify-center opacity-0 transition hover:opacity-100">
            <Trash className="w-4 text-white" />
            <div class="ml-2 text-sm">Reset</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookmarkIcon;
