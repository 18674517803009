import { useState, useEffect } from "react";

function useKeyPress(targetKey, excludeInput = false) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler(e) {
    if (e.key === targetKey) {
      if (!excludeInput || e.target.nodeName !== "INPUT") setKeyPressed(true);
    }
  }

  const upHandler = e => {
    if (e.key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);
  return keyPressed;
}

export default useKeyPress;
