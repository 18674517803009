import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./assets/custom.css";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import "../node_modules/react-date-range/dist/styles.css";
import "../node_modules/react-date-range/dist/theme/default.css";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
