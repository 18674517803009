import React from "react";

const Bookmark = ({ user, bookmark, setBookmark, setModalVisibility }) => {
  const { _id, name, url, icon } = bookmark;

  const handleEdit = () => {
    setBookmark({ ...bookmark });
    setModalVisibility(true);
  };

  const hasEditAccess = user.account_type === "Admin" || bookmark.created_by === user._id;

  return (
    <div class="hover-ring hover-highlight relative flex h-40 cursor-pointer items-center justify-center rounded-md bg-gray-800 p-3 text-sm">
      {hasEditAccess && (
        <div onClick={handleEdit} class="absolute top-1 right-1 w-6 rounded-md border border-transparent p-1 text-gray-500 transition hover:border-gray-500 hover:text-white">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-pen fa-w-16 fa-5x">
            <path
              fill="currentColor"
              d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"
              class=""
            ></path>
          </svg>
        </div>
      )}
      <a href={url} class="flex flex-col items-center justify-center" target="_blank">
        <div class="block pb-4">
          <img class="h-16 w-16 rounded-md" alt="bookmark icon" src={icon || "https://res.cloudinary.com/encorecatering/image/upload/v1644181406/Generic_tsfh3p.ico"} />
        </div>
        <div class="min-h-[32px] text-center text-xs font-medium">{name}</div>
      </a>
    </div>
  );
};

export default Bookmark;
