import React from "react";

const NewBookmarkTile = ({ resetBookmark, setModalVisibility }) => {
  const showModal = () => {
    resetBookmark();
    setModalVisibility(true);
  };

  return (
    <div onClick={showModal} class="hover-ring hover-highlight flex h-40 cursor-pointer flex-col items-center justify-center rounded-md bg-gray-800 p-3 text-sm">
      <div class="block pb-4">
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="h-10 w-10">
          <path
            fill="currentColor"
            d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
            class=""
          ></path>
        </svg>
      </div>
      <div class="w-full self-end text-center text-xs font-medium">Add Bookmark</div>
    </div>
  );
};

export default NewBookmarkTile;
