import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import UnassignedLeadsTable from "../components/Leads/UnassignedLeadsTable";
import RecentConversionsTable from "../components/Leads/RecentConversionsTable";
import { fetchLeadsByDateRange, refreshLeadOwnerAssignments } from "../actions/lead";
import AllLeadsTable from "../components/Leads/AllLeadsTable";
import NotContactedLeads from "../components/Leads/NotContactedLeads";
import InProgressLeads from "../components/Leads/InProgressLeads";
import PieChart from "../components/Leads/PieChart";
import LeadOwnerPieChart from "../components/Leads/LeadOwnerPieChart";
import DateRange from "../components/DateRangePicker/DateRange";
import LeadOwnerSelect from "../components/LeadMetrics/LeadOwnerSelect";
import { addDays, subMonths } from "date-fns";
import LeadTurnedDownReasonChart from "../components/LeadMetrics/LeadTurnedDownReasonChart";
const _ = require("lodash");

const Leads = ({ data, fetchLeadsByDateRange, refreshLeadOwnerAssignments }) => {
  const [dateRange, setDateRange] = useState({ startDate: subMonths(new Date(), 1), endDate: new Date(), key: "selection" });

  console.log({ dateRange });

  const [leads, setLeads] = useState({
    all: [],
    unassigned: [],
    assigned: [],
    not_contacted: [],
    converted: [],
    refused: [],
    contacted: [],
  });

  console.log(leads);

  const [coordinator, setCoordinator] = useState(null);

  useEffect(() => {
    if (data.length) {
      let filteredData = coordinator ? data.filter(lead => lead.owner_full_name === coordinator) : data;
      const exclusions = ["Duplicate", "Spam"];
      filteredData = filteredData.filter(lead => !exclusions.includes(lead.turned_down_reason));
      setLeads({
        all: filteredData,
        unassigned: filteredData.filter(lead => lead.status === "unassigned"),
        not_contacted: filteredData.filter(lead => lead.status === "assigned"),
        contacted: filteredData.filter(lead => lead.status === "contacted"),
        assigned: filteredData.filter(lead => lead.assigned_at),
        turned_down: filteredData.filter(lead => lead.status === "turned_down"),
        with_owner: filteredData.filter(lead => lead.owner && lead.owner.first_name),
        converted: filteredData.filter(lead => lead.converted_at),
        refused: filteredData.filter(lead => lead.turned_down_reason && lead.turned_down_reason.includes("Refused")),
        avgAssignmentTime: getAverageInHours(filteredData.map(lead => lead.time_to_assignment)),
        avgContactTime: getAverageInHours(filteredData.map(lead => lead.time_assigned_to_contact)),
        avgConversionTime: getAverageInHours(filteredData.map(lead => lead.converted_at && lead.time_to_conversion)),
      });
    }
  }, [data, coordinator]);

  const fetchData = async (startDate, endDate) => {
    await fetchLeadsByDateRange(startDate, endDate);
    refreshLeadOwnerAssignments(true);
    console.log("leads retrieved successfully");
  };

  useEffect(() => {
    fetchData(dateRange.startDate, dateRange.endDate);
  }, [dateRange.startDate, dateRange.endDate]);

  const getAverageInHours = arr => {
    const values = arr.filter(Boolean);
    const mean = _.mean(values);
    const hours = mean / 1000 / 60 / 60;
    return hours.toFixed(2);
  };

  const Statistic = ({ label, quantity, unit }) => {
    return (
      <div class=" w-[250px] rounded-md bg-gray-800  p-6 text-center shadow-xl hover:bg-gray-600">
        <div class="text-3xl text-white">
          {quantity}
          <span class="ml-1 text-sm italic text-gray-200">{unit}</span>
        </div>
        <div class="mb-1 mt-2 text-xs font-medium uppercase text-gray-400">{label}</div>
      </div>
    );
  };

  const Heading = ({ label }) => <h1 className="mb-2 mt-8 rounded bg-gray-800 px-6 py-4 text-lg font-medium uppercase tracking-wide text-white">{label}</h1>;

  const handleLeadOwnerChange = e => {
    setCoordinator(e ? e.value : null);
  };

  return (
    <div>
      <div class="mb-6 flex space-x-2">
        <DateRange dateRange={dateRange} setDateRange={setDateRange} label="Period" />
        <LeadOwnerSelect leads={leads.all} onChange={handleLeadOwnerChange} value={coordinator} />
      </div>
      <div class="flex space-x-4">
        <Statistic label="Total" quantity={leads.all.length} unit="leads" />
        <Statistic label="Converted" quantity={leads.converted.length} unit="leads" />
        <Statistic label="Conversion Rate" quantity={((leads.converted.length / leads.all.length) * 100).toFixed(2)} unit="%" />
        <Statistic label="Refusal Rate" quantity={((leads.refused.length / leads.all.length) * 100).toFixed(2)} unit="%" />
        <Statistic label="Avg Time To Assignment" quantity={leads.avgAssignmentTime} unit="hrs" />
        <Statistic label="Avg Time To First Contact" quantity={leads.avgContactTime} unit="hrs" />
        <Statistic label="Avg Conversion Time" quantity={leads.avgConversionTime} unit="hrs" />
      </div>

      <div className="flex">
        <div className="w-[1000px] rounded-md">
          <Heading label="Unassigned Leads" />
          <LeadTurnedDownReasonChart leads={leads.turned_down} />
        </div>
        <div className="flex w-[calc(100%_-_1000px)] items-center justify-center rounded-md p-2">
          <div className="w-full">
            <LeadOwnerPieChart leadsWithOwner={leads.with_owner} />
          </div>
        </div>
      </div>
      <div className="rounded-md">
        <Heading label="All Leads" />
        <AllLeadsTable data={leads.all} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  data: state.lead.leadsByDateRange,
});

export default connect(mapStateToProps, { fetchLeadsByDateRange, refreshLeadOwnerAssignments })(Leads);
