import { SET_LEAD, SET_LEADS, SET_LEADS_BY_DATERANGE, RESET_LEAD, DELETE_LEAD, UPDATE_LEAD, CREATE_LEAD } from "../actions/types";

const defaultState = {
  lead: {},
  leads: [],
  leadsByDateRange: [],
};

const leadReducer = (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case UPDATE_LEAD:
      return {
        ...state,
        lead: { ...payload },
        leads: [...state.leads.map(lead => (lead._id === payload._id ? payload : lead))],
      };
    case CREATE_LEAD:
      return {
        ...state,
        lead: { ...payload },
        leads: [...state.leads, payload],
      };
    case SET_LEAD:
      return {
        ...state,
        lead: { ...payload },
      };
    case DELETE_LEAD:
      return {
        ...state,
        lead: { ...defaultState.lead },
        leads: [...state.leads.filter(lead => lead._id !== payload.id)],
      };
    case RESET_LEAD:
      return {
        ...state,
        lead: { ...defaultState.lead },
      };
    case SET_LEADS:
      return {
        ...state,
        leads: [...payload],
      };
    case SET_LEADS_BY_DATERANGE:
      return {
        ...state,
        leadsByDateRange: [...payload],
      };
    default:
      return state;
  }
};

export default leadReducer;
