import React, { useState } from "react";
import axios from "../../actions/axios";
import PrimaryButton from "../Elements/PrimaryButton";

const NotContactedLeadsReminderBtn = ({ rowData }) => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const handleClick = async e => {
    setLoading(true);
    const data = rowData.data;
    const res = await axios.post("/api/gapi", {
      action: "sendLeadContactReminder",
      data: {
        leadId: data.lead_id,
        leadOwner: data.owner.first_name,
        leadName: data.full_name,
      },
    });
    console.log(res.data);
    if (res.data.success) setSent(true);
    else {
      window.alert("There was an error when sending this reminder. Please try once more, if you receive another error please notify Michael.");
    }
    setLoading(false);
  };

  return (
    <p>
      {!sent && (
        <PrimaryButton loading={loading} onClick={handleClick}>
          Send Reminder
        </PrimaryButton>
      )}
      {sent && (
        <a target="_blank" class="ml-3 inline-flex w-full cursor-pointer items-center justify-center rounded-md border border-transparent bg-gray-900 px-3 py-2 text-sm  font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-700 focus:outline-none sm:text-sm sm:leading-5">
          Reminder Emailed
        </a>
      )}
    </p>
  );
};

export default NotContactedLeadsReminderBtn;
