import { toReportURL } from "./utils";
import moment from "moment";
const Papa = require("papaparse");

export async function getRecord(module, id, showFinancial) {
  let path = "/" + module + "/" + id;
  if (showFinancial) path += "?show_financial=true";

  const data = await this.get(path);
  if (typeof data === "string") return null;
  else if (data.success === false) return null;

  const record = data[module.slice(0, -1)];

  // Add custom fields as regular fields
  const fields = record.custom_fields || [];
  for (const field of fields) {
    const { custom_field_name, value } = field;
    record[custom_field_name] = value;
  }
  return record;
}

/**
 * get leads
 * @param {*} module
 * @param {*} options
 * maxResults: integer
 * orderBy:  created_at | updated_at | name | event_start
 * sortDirection: desc | asc
 * query: searches for events by name, contact, account, email, phone
 * filter |
 * customFields: true | false
 */
export async function getRecords(module, options) {
  var options = options || {};
  var maxResults = options.maxResults || "1000";
  var pages = Math.ceil(maxResults / 50);
  var orderBy = options.orderBy;
  var sortDirection = options.sortDirection;
  var searchQuery = options.query;

  var filter = options.filter || [];
  var filterQuery = filter
    .map(function (f) {
      return "&" + f.key + "=" + f.value;
    })
    .join("");

  // Build query string

  var query = "";
  if (orderBy) query += "&order=" + orderBy;
  if (sortDirection) query += "&sort_direction=" + sortDirection;
  if (filterQuery) query += filterQuery;
  if (searchQuery) query += "&query=" + searchQuery;

  var path = "/" + module + "/search?page=1" + query;
  const res = await this.get(path);
  var results = res["results"];
  var pages = Math.min(res["total_pages"], pages);

  for (var i = 2; i <= pages; i++) {
    var path = "/" + module + "/search?page=" + i + query;
    const res = await this.get(path);
    var results = results.concat(res["results"]);
  }

  // Restructure custom fields as regular fields
  if (options.customFields) {
    results = results.map(record => {
      const fields = record.custom_fields || [];
      for (const field of fields) {
        const { custom_field_name, value } = field;
        record[custom_field_name] = value;
      }
      return record;
    });
  }

  return results;
}

export async function getReport(module, params) {
  module = module.slice(0, -1);

  const currentDate = new Date().toLocaleDateString();
  const startDate = moment(currentDate).subtract(3, "months").format("L");
  const dateRange = `${startDate} - ${currentDate}`;

  const defaultParams = {
    event_daterange: dateRange,
    event_daterangeterm: "mtd",
    status_parent_type: "",
    lead_status: "all",
    lead_source_id: "66130",
    group_by: "",
    sort_by: "leads.created_at",
    sort_dir: "desc",
    custom_report_id: "",
    columns: ["id", "location", "first_name", "guest_count", "event_date", "end_time", "start_time", "last_name", "email", "submitted", "converted", "viewed", "owner", "additional_information"],
  };

  params = params || defaultParams;

  const path = `/reports/${module}_csv?` + toReportURL(params);

  const res = await this.bget(path, null, true);
  const html = res.data;

  const parsed = Papa.parse(html);
  const data = parsed.data;
  const results = [];
  const headers = data.shift().map(header => header.trim());

  const parseAdditionalInfo = result => {
    const info = result["Additional Information"].split(/--/g);
    console.log(info);
    return result;
  };

  for (var k = 0; k < data.length; k++) {
    var row = data[k];
    var result = {};
    for (var j = 0; j < headers.length; j++) {
      var key = headers[j];
      result[key] = row[j];
    }
    if (result.Id) results.push(parseAdditionalInfo(result));
  }
  // separate additional info

  return results;
}
