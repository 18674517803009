import axios from "./axios";
import { SET_TAG, SET_TAGS, RESET_TAG, DELETE_TAG } from "./types";
import { setAlert } from "./alert";

export const fetchTags = (filter) => async (dispatch) => {
  try {
    const res = await axios.get("/api/tag", { params: filter });
    dispatch({
      type: SET_TAGS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const fetchTag = (filter) => async (dispatch) => {
  try {
    const res = await axios.get("/api/tag", { params: filter });
    dispatch({
      type: SET_TAG,
      payload: res.data[0],
    });
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const fetchTagById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/tag/${id}`);
    dispatch({
      type: SET_TAG,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const setTag = (tag) => async (dispatch) => {
  dispatch({
    type: SET_TAG,
    payload: tag,
  });
};

export const setTags = (tags) => async (dispatch) => {
  dispatch({
    type: SET_TAGS,
    payload: tags,
  });
};

export const resetTag = () => async (dispatch) => {
  dispatch({
    type: RESET_TAG,
  });
};

export const upsertTag = (tag) => async (dispatch) => {
  try {
    await axios.post(`/api/tag`, tag);
    dispatch(setAlert("success", "Success! The changes were successfully saved."));
    dispatch({
      type: SET_TAG,
      payload: tag,
    });
    return tag;
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const upsertTags = (tags) => async (dispatch) => {
  try {
    let res = await Promise.all(tags.map(async (tag) => await axios.post(`/api/tag`, tag)));
    let data = res.map((r) => r.data);
    console.tag(res);
    dispatch({
      type: SET_TAGS,
      payload: data,
    });
    dispatch(setAlert("success", "Success! The changes were successfully saved."));
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const deleteTag = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/tag/${id}`);
    dispatch({
      type: DELETE_TAG,
      payload: { id },
    });
    dispatch(setAlert("success", "Successfully deleted the tag"));
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};
