export const chunk = (arr, size) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));

export const calculateAge = date => {
  const secondsDiff = (new Date() - new Date(date)) / 1000;
  const minutesDiff = secondsDiff / 60;
  const hoursDiff = minutesDiff / 60;
  const daysDiff = hoursDiff / 24;
  if (secondsDiff < 60) return `${secondsDiff.toFixed(0)} secs ago`;
  else if (minutesDiff < 60) return `${minutesDiff.toFixed(0)} mins ago`;
  else if (hoursDiff < 24) return `${hoursDiff.toFixed(0)} hrs ago`;
  else return `${daysDiff.toFixed(0)} days ago`;
};
