import axios from "./axios";
import { setAlert } from "./alert";
import { REGISTER_SUCCESS, REGISTER_FAIL, USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, UPDATE_USER } from "./types";
import setAuthToken from "../utils/setAuthToken";

// Load User

export const loadUser = () => async dispatch => {
  if (localStorage.token) setAuthToken(localStorage.token);

  try {
    const res = await axios.get("/api/auth");
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User

export const register = user => async dispatch => {
  try {
    const res = await axios.post("/api/users/register", user);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert("error", err.response.data.msg));
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Login User

export const login = (email, password) => async dispatch => {
  try {
    const res = await axios.post("/api/auth", { email, password }); // Returns { token: }
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
    return res;
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    return err.response.data;
  }
};

export const loginWithMSAL = accessToken => async dispatch => {
  try {
    const res = await axios.post("/api/auth/msal", { accessToken }); // Returns { token: }
    if (res.data.error) return res.data;
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
    return res;
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    return err.response.data;
  }
};

// Logout

export const logout = () => dispatch => {
  dispatch({
    type: LOGOUT,
  });
};

export const resetPassword = email => async dispatch => {
  try {
    await axios.post("/api/auth/reset", { email });
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const updatePassword = password => async dispatch => {
  try {
    await axios.post("/api/auth/update", { password });
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
    else dispatch(setAlert("error", "An unexpected error occured. Please try again later."));
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const updateUser = user => async dispatch => {
  try {
    const res = await axios.patch(`/api/users`, user);
    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });
    dispatch(setAlert("success", "Success! The changes were successfully saved."));
  } catch (err) {
    dispatch(setAlert("error", err.response.data.msg));
  }
};
