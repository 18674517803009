import React, { useState, useEffect } from "react";
import GeneralSelect from "../Layout/Select";

const LeadOwnerSelect = ({ leads, value, onChange }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const owners = [...new Set(leads.map(lead => lead.owner_full_name).filter(Boolean))];
    const options = owners.map(owner => ({ value: owner, label: owner }));
    console.log(options);
    setOptions(options);
  }, [leads]);

  const styleOverride = {
    input: styles => ({ ...styles, color: "white", fontSize: "1rem", padding: "0.15rem", paddingLeft: "0", margin: "0" }),
  };

  return (
    <div class="flex flex-col">
      <label className="mb-1 block text-sm font-medium leading-5 text-gray-400">Coordinator</label>
      <GeneralSelect className="w-[400px]" options={options} value={value ? { value, label: value } : { value: null, label: "All" }} placeholder="Select a coordinator" onChange={onChange} isClearable={true} styleOverride={styleOverride} />
    </div>
  );
};

export default LeadOwnerSelect;
