import React, { useState, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../actions/auth";
import MicrosoftLogin from "../components/MicrosoftLogin/MicrosoftLogin";

// Pass this component the actions it will use and pull out the props it will need
const Login = ({ login, isAuthenticated, user }) => {
  const [formData, setFormData] = useState({});

  const [error, setError] = useState("");

  const params = useParams();

  useEffect(() => {
    const { email, password } = params;
    setFormData({ email, password });
  }, [params]);

  // Destructure and pull out the required constants out of the state
  const { email, password } = formData;

  // This will update the state with the changes as they occur
  const onChange = e => setFormData({ ...formData, [e.target.id]: e.target.value });

  // Trigger the login action on submit
  const onSubmit = async e => {
    e.preventDefault();
    setError("");
    const res = await login(email, password);
    if (res.msg) setError(res.msg);
  };

  // // Navigate if authenticated
  if (isAuthenticated && user.first_name) {
    return <Navigate to="/home" />;
  }

  return (
    <div className="-ml-72 flex flex-col items-center sm:px-6 md:py-12 lg:px-8">
      <div className="z-10 mt-24 sm:mx-auto sm:mt-40 sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
          <h2 className="text-cool-gray-800 mb-8 mt-8 text-center text-3xl font-extrabold leading-9">Sign in to your account</h2>
          <form onSubmit={onSubmit}>
            {/* <div>
              <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                Email address
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input id="email" type="email" required value={email} onChange={onChange} className="focus:shadow-outline-indigo block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-indigo-300 focus:outline-none sm:text-sm sm:leading-5" />
              </div>
            </div>

            <div className="mt-6">
              <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                Password
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input id="password" type="password" required value={password} onChange={onChange} className="focus:shadow-outline-indigo block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-indigo-300 focus:outline-none sm:text-sm sm:leading-5" />
              </div>
            </div> */}

            {/* <div className="mt-6 flex items-center justify-between">
              <div className="flex items-center">
                <input id="remember_me" type="checkbox" className="form-checkbox h-4 w-4 text-indigo-700 transition duration-150 ease-in-out" />
                <label htmlFor="remember_me" className="ml-2 block text-sm leading-5 text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm leading-5">
                <Link to="/forgot-password" className="font-medium text-indigo-700 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline focus:outline-none">
                  Forgot your password?
                </Link>
              </div>
            </div> */}

            <div className="mt-6">
              <div className="w-full">
                <MicrosoftLogin />
              </div>
            </div>
            {error && <p className="mt-6 text-center text-sm text-red-600">{error}</p>}
          </form>
          <p className="mt-6 -mb-2 block text-center text-sm font-medium leading-5 text-gray-700"></p>
        </div>
      </div>
    </div>
  );
};

// This pulls the auth object from the App-level state into the component state
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { login })(Login);
