import React from "react";

const OutlineButton = ({ onClick, children }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      class="mt-6 inline-flex w-full cursor-pointer items-center justify-center rounded-md border border-gray-500 bg-gray-900 px-4 py-2 text-sm font-medium leading-6 text-gray-300 shadow-sm transition duration-150 ease-in-out hover:bg-gray-200 hover:text-gray-900 focus:outline-none sm:text-sm sm:leading-5 "
    >
      {children}
    </button>
  );
};

export default OutlineButton;
