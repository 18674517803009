import axios from "../axios";
import { toReportURL, HTMLTableToJSON } from "./utils";
import moment from "moment";
import cheerio from "cheerio";

export async function getUsers() {
  const res = await this.get("/users");
  return res.results;
}

export async function getUser(full_name = "") {
  const users = await this.getUsers();
  console.log({ full_name });
  const user = users.find(user => `${user.first_name} ${user.last_name}` == full_name.trim());
  console.log({ user });
  return user;
}

export async function getLeadOwnerAssignments() {
  const params = {
    activity_daterange: moment().subtract(1, "day").format("L") + " - " + moment().format("L"),
    activity_daterangeterm: "",
    action_type: ["REASSIGN_LEAD_OWNERSHIP"],
    group_by: "",
    sort_by: "created_at",
    sort_dir: "desc",
    custom_report_id: "",
    columns: ["action_type", "user", "created_at", "message", "lead"],
  };
  const url = "/reports/activity?" + toReportURL(params);
  const html = await this.bget(url);
  return HTMLTableToJSON(html);
}

export async function updateLeadOwnerAssignments() {
  const assignments = await this.getLeadOwnerAssignments();
  const updatedLeads = [];
  for (const assignment of assignments) {
    const { created_at, lead: lead_id, message } = assignment;
    const res = await axios.get("/api/lead", { params: { lead_id } });
    const lead = res.data[0];
    if (lead && !lead.assigned_at) {
      const res = await axios.post(`/api/lead`, {
        ...lead,
        owner: await this.getUser(message.match(/re-assigned from .* to (.*)/)[1]),
        assigned_at: new Date(created_at),
      });
      updatedLeads.push(res.data);
    }
  }
}

export async function getLeadSources() {
  const res = await this.bget("/settings/preferences/dropdowns");
  const $ = cheerio.load(res.data);
  const ref = {};
  $("#LeadSource")
    .find(".dropdown_section .custom_field")
    .each(function () {
      const name = $(this).find("input").val();
      const id = $(this).next("input").val();
      ref[name] = id;
    });
  return ref;
}

export async function syncLead(leadId) {
  const res = await axios.post("/api/lead/sync", { leadId });
  return res.data;
}

/**
 * location, first_name, last_name, email_address, phone_number, lead_source_id, company, event_date, guest_count, event_description, additional_information, start_time, end_time, referral_source_id, location_id
 */

export async function createLead(data) {
  const res = await this.lead(data);
  const leadId = res.id;
  return { ...data, id: leadId };
}

export async function updateLead(id, data) {
  const payload = {
    _method: "patch",
    commit: "Update",
    lead: { ...data },
  };
  const path = "/leads/" + id;
  const res = await this.bpost(path, payload);
  return res;
}

/**
 * first_name, last_name, email_address, phone_number, lead_source_id, company, event_date, guest_count, event_description, additional_information, start_time, end_time, referral_source_id, location_id
 */
export async function alternativeCreateLead(data) {
  data.location_id = data.location === "F+B Kosher" ? "10255" : "7704";
  const payload = {
    lead: { ...data },
  };
  const path = "/leads";
  const res = await this.bpost(path, payload);
  console.log(res.success);
  const leads = await this.getRecords("leads", {
    maxResults: 1,
    orderBy: "created_at",
    sortDirection: "desc",
    query: data.email_address,
  });
  console.log(leads);
  return leads[0];
}

export async function getLeadsListHTML() {
  const res = await this.bget("/leads", {
    lead_status: "not_converted",
    daterangeterm: "selected_dates",
    daterange: new Date().toLocaleDateString(),
    date_field_type: "lead_submitted",
    sort_order: "lead_submitted",
    sort_dir: "desc",
  });
  console.log(res);
}
