import React from "react";

const EncoreLogo = () => {
  return (
    <div class="mb-16 w-full">
      <a href="https://encorecatering.com" target="_blank" rel="noreferrer" className="mx-auto block w-96 transition duration-300 hover:scale-105">
        <img className="w-96" src="https://res.cloudinary.com/encorecatering/image/upload/v1624322460/Email%20Assets/ba875137-82b1-4a85-b7cf-0f52a96e5d99_ectdm1.png" alt="Encore Catering logo" />
      </a>
    </div>
  );
};

export default EncoreLogo;
