import React, { useState, useEffect } from "react";
import MenuSelect from "./MenuSelect";
import LineChart from "./SampleLine";
import TimeGroupSelect from "./TimeGroupSelect";
import moment from "moment";
const groupBy = require("lodash/groupBy");

const MenuDownloadTimeSeries = React.forwardRef(({ style, className, ...props }, ref) => {
  const [menu, setMenu] = useState({});
  const [timeGroup, setTimeGroup] = useState({ value: "days", label: "Days" });
  const [primaryData, setPrimaryData] = useState([]);
  const [comparisonData, setComparisonData] = useState([]);

  function restructureData(downloads) {
    const func = {
      days: download => moment(download.submission_date).format("YYYY-MM-DD"),
      weeks: download => moment(download.submission_date).startOf("week").format("YYYY-MM-DD"),
      months: download => moment(download.submission_date).startOf("month").format("YYYY-MM-DD"),
      years: download => moment(download.submission_date).startOf("year").format("YYYY-MM-DD"),
    };
    const group = groupBy(downloads, func[timeGroup.value]);
    console.log({ group });
    const res = [];
    Object.keys(group).forEach(key => {
      res.push({ x: key, y: group[key].length });
    });
    res.sort((d1, d2) => new Date(d1.x).getTime() - new Date(d2.x).getTime());
    console.log({ downloads, res });
    return res;
  }

  useEffect(() => {
    if (menu.value) {
      const primaryDownloads = menu.value === "All Menu Downloads" ? Object.values(props.data.primaryGroup).flat() : props.data.primaryGroup[menu.value];
      setPrimaryData(restructureData(primaryDownloads));
      const comparisonDownloads = props.data.comparisonGroup[menu.value];
      setComparisonData(restructureData(comparisonDownloads));
    }
  }, [menu, timeGroup]);

  return (
    <div style={{ ...style }} className={className} ref={ref}>
      <h1 className="my-drag-handle">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </h1>
      <LineChart primaryData={primaryData} comparisonData={comparisonData} />
      <div class="mt-2 flex w-full items-center space-x-1">
        <div class="w-1/2">
          <MenuSelect menu={menu} setMenu={setMenu} menus={props.data.menus || []} />
        </div>
        <div class="w-1/2">
          <TimeGroupSelect timeGroup={timeGroup} setTimeGroup={setTimeGroup} />
        </div>
      </div>
    </div>
  );
});

export default MenuDownloadTimeSeries;
