import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import useKeyPress from "../Hooks/useKeyPress";
import { Input } from "../Elements";
import Fuse from "fuse.js";

const BookmarkSearch = ({ bookmarks }) => {
  const [visible, setVisibility] = useState(false);
  const [query, setQuery] = useState("");
  const [active, setActive] = useState(0);

  const EnterKeyPress = useKeyPress("Enter");
  const slashKeyPress = useKeyPress("/", true);
  const EscKeyPress = useKeyPress("Escape");
  const ArrowDown = useKeyPress("ArrowDown");
  const ArrowUp = useKeyPress("ArrowUp");

  const fuse = new Fuse(bookmarks, { keys: ["name", "url", "description"] });
  const results = fuse.search(query);
  const filteredBookmarks = results.length ? results.map(r => r.item).slice(0, 10) : bookmarks.slice(0, 10);
  // if (filteredBookmarks.length && filteredBookmarks[0]._id !== active._id) setActive(filteredBookmarks[0]);

  useEffect(() => {
    if (slashKeyPress) return setVisibility(true);
  }, [slashKeyPress]);

  useEffect(() => {
    if (EscKeyPress) handleClose();
  }, [EscKeyPress]);

  useEffect(() => {
    if (visible && EnterKeyPress && filteredBookmarks.length) {
      setTimeout(() => window.open(filteredBookmarks[active].url, "_blank", "noopener,noreferrer"), 100);
    }
  }, [EnterKeyPress]);

  useEffect(() => {
    if (ArrowUp) {
      const updated = Math.max(0, active - 1);
      setActive(updated);
    }
  }, [ArrowUp]);

  useEffect(() => {
    if (ArrowDown) {
      let updated = active + 1 >= filteredBookmarks.length ? 0 : active + 1;
      setActive(updated);
    }
  }, [ArrowDown]);

  useEffect(() => {
    if (visible) document.body.classList.add("overflow-hidden");
    else document.body.classList.remove("overflow-hidden");
  }, [visible]);

  const handleChange = e => {
    const query = e.target.value;
    if (query !== "/") setQuery(query);
    setActive(0);
  };

  const handleClose = () => {
    setQuery("");
    setActive(0);
    setVisibility(false);
  };

  return (
    <>
      {visible && (
        <div class="fixed inset-x-0 bottom-0 z-10 p-10 transition duration-300 sm:inset-0 sm:flex sm:items-center sm:justify-center sm:p-0">
          <div onClick={handleClose} class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-900 opacity-80"></div>
          </div>
          <div class="h-[730px] transform rounded-lg border-2 border-gray-400 bg-gray-900 px-14 pt-12 pb-24 shadow-xl shadow-gray-800 transition-all sm:w-full sm:max-w-2xl">
            <div className="mb-8">
              <Input placeholder="Search for a bookmark..." id="query" onChange={handleChange} value={query} autoFocus={true} />
            </div>
            <div className="h-[550px] overflow-scroll">
              {filteredBookmarks.map((item, index) => {
                return (
                  <a key={item._id} href={item.url} target="_blank">
                    <div
                      className={`text-md my-2 flex w-full cursor-pointer items-center rounded-lg p-4 font-medium text-white placeholder-gray-500 outline-none transition duration-200 hover:bg-gray-600 focus:bg-gray-600 ${
                        index === active ? "bg-sky-600" : "bg-gray-800"
                      }`}
                    >
                      <img className="mr-2 h-12 w-12 rounded-md" src={item.icon || "https://res.cloudinary.com/encorecatering/image/upload/v1644181406/Generic_tsfh3p.ico"} />
                      <div>{item.name}</div>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  bookmarks: state.user.user.value === "All Employees" ? state.bookmark.bookmarks : state.user.user.bookmarks || [],
});

export default connect(mapStateToProps, {})(BookmarkSearch);
