import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, UsersIcon, LogoutIcon, PlusIcon, DownloadIcon, TagIcon, LibraryIcon, BadgeCheckIcon, CogIcon, ShoppingCartIcon, AtSymbolIcon, UserGroupIcon, PresentationChartLineIcon } from "@heroicons/react/outline";
import UserSelect from "../components/UserSelect/UserSelect";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { logout } from "../actions/auth";

const Sidebar = ({ isAdmin, logout, currentUser }) => {
  let navigate = useNavigate();
  let location = useLocation();

  const [Navigation, setNavigation] = useState([]);

  const handleSignOut = () => {
    logout();
    navigate("/login");
  };

  const { account_type, first_name, last_name, value } = currentUser;

  const navigation = isAdmin
    ? [
        { name: "Bookmarks", href: "/home", icon: HomeIcon, current: () => window.location.href.includes("/home") },
        { name: "Menu Downloads", href: "/menu-downloads", icon: DownloadIcon, current: () => window.location.href.includes("/menu-downloads") },
        { name: "Active Leads", href: "/leads", icon: UsersIcon, current: () => window.location.href.includes("/leads") },
        { name: "Lead Metrics", href: "/lead-metrics", icon: PresentationChartLineIcon, current: () => window.location.href.includes("/lead-metrics") },
        { name: "Bookings", href: "/bookings", icon: FolderIcon, current: () => window.location.href.includes("/bookings") },
        { name: "Proposals", href: "/proposals", icon: CalendarIcon, current: () => window.location.href.includes("/proposals") },
        { name: "Coordinators", href: "/coordinators", icon: ChartBarIcon, current: () => window.location.href.includes("/coordinators") },
        { name: "WooCommerce", href: "/ecommerce", icon: ShoppingCartIcon, current: () => window.location.href.includes("/coordinators") },
        { name: "Mailchimp", href: "/mailchimp", icon: AtSymbolIcon, current: () => window.location.href.includes("/coordinators") },
        { name: "VEO", href: "/veo", icon: UserGroupIcon, current: () => window.location.href.includes("/coordinators") },
        { name: "Settings", href: "/settings", icon: CogIcon, current: () => window.location.href.includes("/settings") },
      ]
    : [
        { name: "Bookmarks", href: "/home", icon: HomeIcon, current: () => window.location.href.includes("/home") },
        { name: "Tags", href: "/tags", icon: TagIcon, current: () => window.location.href.includes("/tags") },
        { name: "Tasks", href: "/tasks", icon: BadgeCheckIcon, current: () => window.location.href.includes("/tasks") },
        { name: "Training", href: "/training", icon: LibraryIcon, current: () => window.location.href.includes("/training") },
        { name: "Settings", href: "/settings", icon: CogIcon, current: () => window.location.href.includes("/settings") },
      ];

  useEffect(() => {
    setNavigation(
      navigation.map(item => (
        <Link key={item.name} to={item.href} className={classNames(window.location.href.includes(item.href) ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white", "group flex items-center rounded-md px-2 py-2 text-sm font-medium")}>
          <item.icon className={classNames(window.location.href.includes(item.href) ? "text-gray-300" : "text-gray-400 group-hover:text-gray-300", "mr-3 h-6 w-6 flex-shrink-0")} aria-hidden="true" />
          {item.name}
        </Link>
      ))
    );
  }, [location.pathname, isAdmin]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  if (!currentUser?.id) return null;

  return (
    <div class="fixed inset-y-0 w-72 bg-gray-800">
      <div className="flex h-full flex-1 flex-col bg-gray-800">
        <div className=" mt-4 w-full px-2 text-2xl font-bold leading-none text-white sm:text-3xl">
          {account_type === "Admin" || value === "All Employees" ? (
            <UserSelect />
          ) : (
            <div class="rounded-md bg-gray-800 py-3 pl-5 text-base">
              {first_name} {last_name}
            </div>
          )}
        </div>
        <div className="flex flex-1 flex-col overflow-y-auto">
          <nav className="flex-1 space-y-1 px-2 py-4">{Navigation}</nav>
        </div>
        {isAdmin && (
          <Link to="/register" type="button" className="m-2 items-center rounded-md  bg-gray-800 px-4 py-2 text-center text-sm font-medium text-gray-400 shadow-sm transition  hover:border-gray-100 hover:text-gray-100 focus:outline-none">
            <div class="flex items-center justify-center">
              <span>New user</span>
              <span class="ml-2 w-5">
                <PlusIcon />
              </span>
            </div>
          </Link>
        )}
        <button type="button" onClick={handleSignOut} className="m-2 items-center rounded-md border-2 border-gray-400 bg-gray-800 px-4 py-2 text-center text-sm font-medium text-gray-400 shadow-sm transition  hover:border-gray-100 hover:text-gray-100 focus:outline-none">
          <div class="flex items-center justify-center">
            <span>Sign out</span>
            <span class="ml-2 w-5">
              <LogoutIcon />
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isAdmin: state.auth.user.account_type === "Admin",
  currentUser: state.auth.user,
  user: state.user.user,
});

export default connect(mapStateToProps, { logout })(Sidebar);
