import React, { useState, useEffect, useMemo } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import axios from "../../actions/axios";
import { Link } from "react-router-dom";

const SearchInput = () => {
  const [term, setTerm] = useState("");
  const [results, setResults] = useState([]);
  const [focused, setFocused] = useState(false);
  const [hoverResults, setHoverResults] = useState(false);
  const [csrfToken, setCsrfToken] = useState("");

  const debounce = (fn, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  let fetchSupportResults = async (term, csrfToken) => {
    const res = await axios.post("/api/support", { term, csrfToken });
    console.log(res.data);
    setResults(res.data);
  };

  fetchSupportResults = useMemo(() => debounce(fetchSupportResults, 300), []);

  const handleChange = async e => {
    const term = e.target.value;
    setTerm(term);
    if (!term) setResults([]);
    if (term.length < 4) return;

    if (!csrfToken) {
      const res = await axios.get("/api/support/csrfToken");
      csrfToken = res.data.csrfToken;
    }
    fetchSupportResults(term, csrfToken);
  };

  const fetchCSRFToken = async () => {
    const res = await axios.get("/api/support/csrfToken");
    setCsrfToken(res.data.csrfToken);
  };

  useEffect(() => {
    if (!csrfToken) return fetchCSRFToken;
  }, [focused, csrfToken]);

  const visible = results.length > 0 && (focused || hoverResults);

  return (
    <div class="relative h-full">
      <div className="mt-5">
        <label htmlFor="search" className="sr-only"></label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3" aria-hidden="true">
            <SearchIcon className=" h-6 w-6 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            onChange={handleChange}
            onBlur={() => setFocused(false)}
            onFocus={() => setFocused(true)}
            name="search"
            id="search"
            value={term}
            className="focus-ring text-md block w-full rounded-md border-gray-300 py-3 pl-12 font-medium text-gray-900 outline-none"
            placeholder="Have a question about an internal process? Search away..."
          />
        </div>
      </div>
      {visible && (
        <ul
          onMouseEnter={() => setHoverResults(true)}
          onMouseLeave={() => setHoverResults(false)}
          class="absolute z-10 mt-2 w-full overflow-y-scroll rounded-3xl border border-gray-400 bg-white shadow transition"
          style={{ height: "400px" }}
        >
          {results.map(({ url, title, group, desc }) => {
            const id = url.replace("/support/solutions/articles/", "");
            return (
              <div class="border-b border-gray-300 py-2 pr-4 pl-10 hover:bg-gray-100">
                <Link to={`/article/${id}`} class="article-item" target="_blank" rel="noreferrer">
                  <li class="py-1 pr-4">
                    <div class="flex items-center">
                      <span dangerouslySetInnerHTML={{ __html: title }} class="title text-sm font-bold"></span>
                      <span dangerouslySetInnerHTML={{ __html: group }} class="label ml-2 rounded-full border border-gray-400 bg-gray-200 px-3 py-[2px] text-[10px] font-semibold text-gray-700"></span>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: desc }} class="description mt-1 text-xs text-gray-600"></p>
                  </li>
                </Link>
              </div>
            );
          })}
        </ul>
      )}
      <p className="mt-2 ml-4 text-xs text-gray-400">Hint: Want to search Google? Press "F6" on your keyboard to jump to the address bar at the top and start typing...</p>
    </div>
  );
};

export default SearchInput;
