import React, { useState, useEffect } from "react";
import { Input, OutlineButton } from "../../Elements";
import TagsCreatableMultiSelect from "../../TagSelector/TagsCreatableMultiSelect";
import TagsMultiSelect from "../../TagSelector/TagsMultiSelect";
import UsersMultiSelect from "../../UserSelect/UsersMultiSelect";
import BookmarkModalHeader from "./BookmarkModalHeader";

const BookmarkModalForm = ({ bookmark, user, error, handleChange, handleDelete, handleRemoveUser, handleUrlChange, handleSubmit }) => {
  const [bookmarkType, setBookmarkType] = useState(bookmark.users && bookmark.users.length ? "Shared" : "Personal");

  useEffect(() => {
    setBookmarkType(bookmark.users && bookmark.users.length ? "Shared" : "Personal");
  }, [bookmark.users]);

  return (
    <div>
      <BookmarkModalHeader id={bookmark._id} bookmarkType={bookmarkType} />
      <div class="flex flex-col space-y-4">
        <Input placeholder="Title" id="name" onChange={handleChange} value={bookmark.name} autoFocus={true} />
        <Input placeholder="Website URL" id="url" onChange={handleChange} value={bookmark.url} onBlur={handleUrlChange} />
        {user.account_type === "Admin" && <TagsCreatableMultiSelect id="tags" value={bookmark.tags} onChange={handleChange} />}
        {user.account_type !== "Admin" && <TagsMultiSelect id="tags" value={bookmark.tags} onChange={handleChange} />}
        <UsersMultiSelect id="users" value={bookmark.users} onChange={handleChange} handleRemove={handleRemoveUser} />
      </div>
      <OutlineButton onClick={handleSubmit}>Submit</OutlineButton>
      {bookmarkType === "Shared" && <div class="mt-2 text-center text-xs italic text-gray-400">Updating this bookmark will change it for everyone it's shared with</div>}
      <p class="my-3 text-center text-sm font-semibold text-red-600">&nbsp;{error}</p>
      {bookmark._id && (
        <div class="absolute -ml-14 w-full cursor-pointer text-center text-sm font-semibold ">
          <span onClick={handleDelete} class="text-gray-500 transition hover:text-white">
            Delete Bookmark
          </span>
        </div>
      )}
    </div>
  );
};

export default BookmarkModalForm;
