import axios from "../axios";

export const get = async (path, contentType) => {
  const res = await axios.post("/api/tripleseat/get", { path, contentType });
  return res.data;
};

export const post = async (path, payload) => {
  const res = await axios.post("/api/tripleseat/post", { path, payload });
  return res.data;
};

export const put = async (path, payload) => {
  const res = await axios.post("/api/tripleseat/put", { path, payload });
  return res.data;
};

export const bget = async (path, data, opt_XMLHttpRequest) => {
  const res = await axios.post("/api/tripleseat/bget", { path, data, opt_XMLHttpRequest });
  return res.data;
};

export const bpost = async (path, data) => {
  const res = await axios.post("/api/tripleseat/bpost", { path, data });
  return res.data;
};

export const lead = async data => {
  const res = await axios.post("/api/tripleseat/lead", { data });
  return res.data;
};
