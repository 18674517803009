import React, { useEffect } from "react";
import { connect } from "react-redux";
import Creatable from "react-select/creatable";
import { fetchTags } from "../../actions/tag";

const TagsCreatableMultiSelect = ({ id, value, onChange, options, autoFetch = "true", fetchTags }) => {
  useEffect(() => {
    if (!options.length && autoFetch) fetchTags();
  }, [fetchTags, options.length]);

  const styles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      outline: "none",
      boxShadow: "none",
      borderColor: isFocused ? "#fff" : "transparent",
      ":hover": { borderColor: "#fff" },
      borderRadius: "0.5rem",
      borderWidth: "2px",
      backgroundColor: "rgb(31 41 55)",
      fontSize: "1rem",
      fontWeight: "500",
      color: "white",
    }),
    multiValueRemove: styles => ({
      ...styles,
      ":hover": {
        backgroundColor: "lightgray",
        color: "black",
      },
    }),
    indicatorSeparator: styles => ({ display: "none" }),
    input: styles => ({ ...styles, color: "white", fontSize: "1rem", padding: "0.5rem", paddingLeft: "0", margin: "0" }),
    valueContainer: styles => ({ ...styles, padding: "0.25rem", margin: "0", paddingLeft: "1rem" }),
    singleValue: styles => ({ ...styles, color: "white", fontSize: "1rem" }),
    multiValue: styles => ({ ...styles, backgroundColor: "rgb(107 114 128)", marginLeft: "0", marginRight: "0.5rem", borderRadius: "0.5rem", color: "white", fontSize: "1rem" }),
    multiValueLabel: styles => ({ ...styles, color: "white" }),
    placeholder: styles => ({ ...styles, fontSize: "1rem", color: "rgb(107 114 128)", paddingLeft: "0" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "0.75rem",
      borderRadius: "0.25rem",
      backgroundColor: isFocused ? "rgb(107 114 128)" : "transparent",
      ":hover": {
        backgroundColor: "rgb(107 114 128)",
      },
    }),
    menu: styles => ({ ...styles, zIndex: "50", backgroundColor: "rgb(31 41 55)", color: "white", borderRadius: "0.5rem" }),
  };

  const handleChange = (e = []) => {
    const value = e.map(tag => ({ ...tag, name: tag.value.toLowerCase() }));
    onChange({
      target: { id, value },
    });
  };

  return <Creatable defaultValue={[]} value={value || []} placeholder="Add tags..." onChange={handleChange} options={options} styles={styles} isMulti isClearable={false} />;
};

const mapStateToProps = state => ({
  options: state.tag.tags,
});

export default connect(mapStateToProps, { fetchTags })(TagsCreatableMultiSelect);
