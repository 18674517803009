import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellStyle: { fontSize: "12px" },
};

const UnassignedLeadsTable = ({ data }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleCheckBoxSelection = e => (e.node.isSelected() ? removeLeadFromTable(e) : null);
  const removeLeadFromTable = e => setTableData(tableData.filter(row => row.lead_id !== e.data.lead_id));

  const calculateAge = date => {
    const minutesDiff = (new Date() - new Date(date)) / 1000 / 60;
    const hoursDiff = minutesDiff / 60;
    const daysDiff = hoursDiff / 24;
    if (minutesDiff < 60) return `${minutesDiff.toFixed(0)} mins ago`;
    else if (hoursDiff < 24) return `${hoursDiff.toFixed(0)} hrs ago`;
    else return `${daysDiff.toFixed(0)} days ago`;
  };

  const EventDetails = ({ data }) => {
    if (data["event_date"] && data["start_time"] && data["end_time"]) {
      const formattedDate = `${moment(data["event_date"]).format("MMM D, YYYY")}, ${data["start_time"]} - ${data["end_time"]}`;
      const guestCount = `${data["guest_count"]} Guests`;
      return <MultiLineCell firstValue={formattedDate} secondValue={guestCount} />;
    } else {
      return <div></div>;
    }
  };

  const DateCreated = ({ data }) => {
    const formattedDate = moment(data["created_at"]).format("L");
    const age = calculateAge(data["created_at"]);
    return <MultiLineCell firstValue={formattedDate} secondValue={age} />;
  };

  const MultiLineCell = ({ firstValue, secondValue }) => {
    return (
      <div>
        <div>{firstValue}</div>
        <div class="pb-3 italic text-gray-400" style={{ fontSize: "11px", lineHeight: "0.25" }}>
          {secondValue}
        </div>
      </div>
    );
  };

  const LeadDetails = ({ data }) => {
    const fullName = `${data["first_name"]} ${data["last_name"]}`;
    return <MultiLineCell firstValue={fullName} secondValue={data["email_address"]} />;
  };

  const [columnDefs] = useState([
    { field: "created_at", headerName: "Date Created", cellRenderer: row => <DateCreated data={row.data} />, filter: false, width: 150, checkboxSelection: true },
    { field: "location", headerName: "Company", width: 150 },
    { field: "first_name", headerName: "Full Name", width: 250, cellRenderer: row => <LeadDetails data={row.data} /> },
    { field: "event_date", headerName: "Event", width: 250, cellRenderer: row => <EventDetails data={row.data} />, filter: false, sortable: false, autoHeight: true },
    {
      field: "lead_id",
      headerName: "",
      sortable: false,
      filter: false,
      cellClass: "h-full w-full",
      cellRenderer: data => (
        <a href={`https://encorecatering.tripleseat.com/leads/${data.value}/edit`} target="_blank" className="ml-3 inline-flex cursor-pointer items-center justify-center rounded-md border border-transparent bg-cyan-500 px-6 py-1 text-xs  font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-none sm:text-xs sm:leading-5">
          Assign
        </a>
      ),
    },
  ]);

  const gridConfig = {
    rowData: tableData,
    columnDefs: columnDefs,
    defaultColDef: defaultColDef,
    enableCellTextSelection: true,
    onRowSelected: handleCheckBoxSelection,
    rowHeight: 67,
  };

  return (
    <div className="ag-theme-alpine" style={{ height: "600px" }}>
      <AgGridReact {...gridConfig}></AgGridReact>
    </div>
  );
};

export default UnassignedLeadsTable;
