import React, { useState, useEffect } from "react";
import Select from "../Layout/Select";

const options = ["Days", "Weeks", "Months", "Years"].map(option => ({ value: option.toLowerCase(), label: option }));

const TimeGroupSelect = ({ timeGroup, setTimeGroup }) => {
  const handleChange = e => setTimeGroup(e);

  return <Select value={timeGroup} options={options} onChange={handleChange} />;
};

export default TimeGroupSelect;
