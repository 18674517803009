import React, { useState, useEffect } from "react";
import Select from "../Layout/Select";

const MenuSelect = ({ menu, setMenu, menus = [] }) => {
  const [options, setOptions] = useState([]);

  const handleChange = e => setMenu(e);

  useEffect(() => {
    const options = menus.map(menu => ({ value: menu, label: menu }));
    if (options.length) setOptions([{ value: "All Menu Downloads", label: "All Menu Downloads" }, ...options]);
    if (!menu.value && options.length) setMenu(options[0]);
  }, [menus]);

  return <Select value={menu} options={options} onChange={handleChange} />;
};

export default MenuSelect;
