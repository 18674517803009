import React from "react";
import Select from "react-select/creatable";

const GeneralSelect = ({ value, options, onChange, placeholder, className, isClearable = false, styleOverride = {} }) => {
  const styles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      outline: "none",
      boxShadow: "none",
      borderColor: isFocused ? "#fff" : "transparent",
      ":hover": { borderColor: "#fff" },
      borderRadius: "0.5rem",
      borderWidth: "2px",
      backgroundColor: "rgb(31 41 55)",
      fontSize: "1rem",
      fontWeight: "500",
      color: "white",
    }),
    multiValueRemove: styles => ({
      ...styles,
      ":hover": {
        backgroundColor: "lightgray",
        color: "black",
      },
    }),
    indicatorSeparator: styles => ({ display: "none" }),
    input: styles => ({ ...styles, color: "white", fontSize: "1rem", padding: "0.25rem", paddingLeft: "0", margin: "0" }),
    valueContainer: styles => ({ ...styles, padding: "0.25rem", margin: "0", paddingLeft: "1rem" }),
    singleValue: styles => ({ ...styles, color: "white", fontSize: "1rem" }),
    multiValue: styles => ({ ...styles, backgroundColor: "rgb(107 114 128)", marginLeft: "0", marginRight: "0.5rem", borderRadius: "0.5rem", color: "white", fontSize: "1rem" }),
    multiValueLabel: styles => ({ ...styles, color: "white" }),
    placeholder: styles => ({ ...styles, fontSize: "1rem", color: "rgb(107 114 128)", paddingLeft: "0" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "0.75rem",
      borderRadius: "0.25rem",
      backgroundColor: isFocused ? "rgb(107 114 128)" : "transparent",
      ":hover": {
        backgroundColor: "rgb(107 114 128)",
      },
    }),
    menu: styles => ({ ...styles, zIndex: "999", backgroundColor: "rgb(31 41 55)", color: "white", borderRadius: "0.5rem" }),
    ...styleOverride,
  };

  return (
    <div class={className}>
      <Select defaultValue={[]} value={value || {}} placeholder={placeholder || ""} onChange={onChange} options={options} styles={styles} isClearable={isClearable} />
    </div>
  );
};

export default GeneralSelect;
