import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      labels: {
        color: "white",
      },
    },
    title: {
      display: true,
      text: "Number of Downloads by Menu",
      color: "white",
    },
  },
  scales: {
    x: {
      ticks: { color: "white" },
      grid: { color: "transparent" },
    },
    y: {
      ticks: { color: "white" },
      grid: { color: "#333" },
    },
  },
};

function SampleBar({ data }) {
  const chartData = {
    datasets: [
      {
        label: "Period",
        data: data.primaryMenuCounts,
        backgroundColor: "rgba(255, 99, 132, 0.8)",
        color: "#fff",
      },
      {
        label: "Comparison",
        data: data.comparisonMenuCounts,
        backgroundColor: "rgba(53, 162, 235, 0.8)",
        color: "#fff",
      },
    ],
  };

  return (
    <div>
      <Bar options={options} data={chartData} />
    </div>
  );
}

export default SampleBar;
