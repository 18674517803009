const cheerio = require("cheerio");
const Papa = require("papaparse");

export function toReportURL(params) {
  return Object.keys(params)
    .map(key => {
      const value = params[key];
      if (typeof value == "string") return key + "=" + value.replace(/ /g, "+");
      else if (Array.isArray(value)) {
        return value.map(v => key + "[]=" + v);
      }
    })
    .flat()
    .join("&");
}

export function HTMLTableToJSON(html) {
  const results = [];
  const $ = cheerio.load(html);
  // prettier-ignore
  const headers = $("th").toArray().map(el => $(el).text().trim());
  // prettier-ignore
  var data = $("tbody tr").toArray().map(row => {
    var values = $(row).find("td").toArray().map(col => {
      const link = $(col).find("a").prop("href");
      if (link) {
        const split = link.split("/");
        return split[split.length - 1].replace(/\n/g, "").trim();
      }
      return $(col).text().replace(/\n/g, "").trim();
    });
    return values;
  });

  for (var k = 0; k < data.length; k++) {
    var row = data[k];
    var result = {};
    for (var j = 0; j < headers.length; j++) {
      var key = headers[j].toLowerCase().replace(/ /g, "_");
      result[key] = row[j];
    }
    results.push(result);
  }

  return results;
}

export function CSVToJSON(csv) {
  const parsed = Papa.parse(csv);
  const data = parsed.data;
  const results = [];
  const headers = data.shift().map(header => header.trim());

  for (var k = 0; k < data.length; k++) {
    var row = data[k];
    var result = {};
    for (var j = 0; j < headers.length; j++) {
      var key = headers[j];
      result[key] = row[j];
    }
    results.push(result);
  }

  return results;
}
