import React, { useState } from "react";
import { Input, OutlineButton } from "../../Elements";

const BookmarkModalIconFromURL = ({ setPage, handleChange }) => {
  const [url, setUrl] = useState("");

  const handleUrlChange = e => {
    setUrl(e.target.value);
  };

  const handleSubmit = () => {
    if (url)
      handleChange({
        id: "icon",
        value: url,
      });
    setPage("form");
  };

  return (
    <div class="mt-6">
      <Input onChange={handleUrlChange} value={url} placeholder="Enter the URL for the icon" />
      {url && (
        <div>
          <div class="mt-6 mb-2 text-gray-300">Preview</div>
          <div class="rounded-md border border-gray-600 p-4 text-center">
            <img class="mx-auto h-20 w-20 rounded-md" src={url} />
          </div>
        </div>
      )}
      <OutlineButton onClick={handleSubmit}>Continue</OutlineButton>
    </div>
  );
};

export default BookmarkModalIconFromURL;
