import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import UnassignedLeadsTable from "../components/Leads/UnassignedLeadsTable";
import RecentConversionsTable from "../components/Leads/RecentConversionsTable";
import { fetchLeads, refreshLeadOwnerAssignments } from "../actions/lead";
import AllLeadsTable from "../components/Leads/AllLeadsTable";
import NotContactedLeads from "../components/Leads/NotContactedLeads";
import InProgressLeads from "../components/Leads/InProgressLeads";
import PieChart from "../components/Leads/PieChart";
import LeadOwnerPieChart from "../components/Leads/LeadOwnerPieChart";
const _ = require("lodash");

const Leads = ({ data, fetchLeads, refreshLeadOwnerAssignments }) => {
  const [leads, setLeads] = useState({
    all: [],
    unassigned: [],
    assigned: [],
    converted: [],
    contacted: [],
  });

  useEffect(() => {
    if (data.length) {
      setLeads({
        all: data,
        unassigned: data.filter(lead => lead.status === "unassigned"),
        contacted: data.filter(lead => lead.status === "contacted"),
        assigned: data.filter(lead => lead.assigned_at),
        with_owner: data.filter(lead => lead.owner && lead.owner.first_name),
        converted: data.filter(lead => lead.converted_at),
        avgAssignmentTime: getAverageInHours(data.map(lead => lead.time_to_assignment)),
        avgContactTime: getAverageInHours(data.map(lead => lead.time_assigned_to_contact)),
        avgConversionTime: getAverageInHours(data.map(lead => lead.converted_at && lead.time_to_conversion)),
      });
    }
  }, [data]);

  useEffect(() => {
    if (!data.length) {
      fetchLeads();
      refreshLeadOwnerAssignments();
    }
  }, [data]);

  console.log(leads);

  const getAverageInHours = arr => {
    const values = arr.filter(Boolean);
    const mean = _.mean(values);
    const hours = mean / 1000 / 60 / 60;
    return hours.toFixed(2);
  };

  const Statistic = ({ label, quantity, unit }) => {
    return (
      <div class=" w-[250px] rounded-md  bg-gray-800 p-6 text-center shadow-xl">
        <div class="text-3xl text-white">
          {quantity}
          <span class="ml-1 text-sm italic text-gray-200">{unit}</span>
        </div>
        <div class="mb-1 mt-2 text-xs uppercase text-gray-400">{label}</div>
      </div>
    );
  };

  const Heading = ({ label }) => <h1 className="mb-2 mt-8 rounded bg-gray-800 px-6 py-4 text-lg font-medium uppercase tracking-wide text-white">{label}</h1>;

  return (
    <div>
      <div class="flex space-x-4">
        <Statistic label="Unassigned" quantity={leads.unassigned.length} unit="leads" />
        <Statistic label="Waiting for Outreach" quantity={leads.assigned.length} unit="leads" />
        <Statistic label="In Discussions" quantity={leads.contacted.length} unit="leads" />
        <Statistic label="Avg Time To Assignment" quantity={leads.avgAssignmentTime} unit="hrs" />
        <Statistic label="Avg Time To First Contact" quantity={leads.avgContactTime} unit="hrs" />
        <Statistic label="Avg Conversion Time" quantity={leads.avgConversionTime} unit="hrs" />
      </div>

      <div className="flex">
        <div className="w-[1000px] rounded-md">
          <Heading label="Unassigned Leads" />
          <UnassignedLeadsTable data={leads.unassigned} />
        </div>
        <div className="flex w-[calc(100%_-_1000px)] items-center justify-center rounded-md p-2">
          <div className="w-full">
            <LeadOwnerPieChart leadsWithOwner={leads.with_owner} />
          </div>
        </div>
      </div>
      <div className="rounded-md">
        <Heading label="Not Contacted" />
        <NotContactedLeads data={leads.assigned} />
      </div>
      <div className="rounded-md">
        <Heading label="In Discussions" />
        <InProgressLeads data={leads.contacted} />
      </div>
      <div className="rounded-md">
        <Heading label="All Leads" />
        <AllLeadsTable data={leads.all} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  data: state.lead.leads.filter(lead => ["unassigned", "assigned", "contacted"].includes(lead.status)),
});

export default connect(mapStateToProps, { fetchLeads, refreshLeadOwnerAssignments })(Leads);
