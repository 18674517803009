import React from "react";

const BookmarkModalHeader = ({ id, bookmarkType }) => {
  return (
    <h3 class="mb-10  flex items-center justify-center text-center text-xl font-medium leading-6 text-gray-100" id="modal-headline">
      {id ? `Edit Bookmark` : "Create new bookmark"}
      <span class="ml-4 rounded-full bg-gray-400 py-1 px-2 text-xs text-gray-900">{bookmarkType}</span>
    </h3>
  );
};

export default BookmarkModalHeader;
