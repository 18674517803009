import React from "react";

const PrimaryButton = ({ id, onClick, children, style, loading, classes = "" }) => {
  const defaultClasses = {
    root: "ml-3 inline-flex w-full cursor-pointer items-center justify-center rounded-md border border-transparent bg-gray-900 px-4 py-2 text-sm  font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-gray-700 focus:outline-none sm:text-sm sm:leading-5",
  };

  const prefixes = classes.split(" ").map(cls => cls.split("-")[0]);

  classes = {
    root:
      defaultClasses.root
        .split(" ")
        .filter(cls => !prefixes.includes(cls.split("-")[0]))
        .join(" ") +
      " " +
      classes,
  };

  return (
    <button id={id} onClick={onClick} style={style} type="button" class={classes.root}>
      {loading && (
        <span class="mr-2">
          <span class="spinner-button -ml-2 mr-2"></span>
        </span>
      )}
      {children}
    </button>
  );
};

export default PrimaryButton;
