// import * as bookingMethods from './bookings'
// import * as eventMethods from './events'
import * as discussionMethods from "./discussions";
import * as leadMethods from "./leads";
import * as operationMethods from "./operations";
import * as taskMethods from "./tasks";
import * as endpoints from "./endpoints";

export const TripleseatMethods = {
  ...discussionMethods,
  ...leadMethods,
  ...operationMethods,
  ...taskMethods,
};

export const Tripleseat = {
  ...endpoints,
  ...TripleseatMethods,
};
