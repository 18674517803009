import React, { useState, useCallback, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import CreateLeadButton from "./CreateLeadButton";
import PrimaryButton from "../Elements/PrimaryButton";

const defaultColDef = {
  sortable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  resizable: true,
};

const MenuDownloadsTable = ({ data }) => {
  const gridRef = useRef();

  const [columnDefs] = useState([
    { field: "submission_date", headerName: "Date", valueFormatter: params => moment(params.value).format("LLL"), filter: false, sort: "desc" },
    { field: "location", headerName: "Location" },
    { field: "menu", headerName: "Menu" },
    { field: "first_name", headerName: "First Name" },
    { field: "last_name", headerName: "Last Name" },
    { field: "email_address", headerName: "Email", width: 350 },
    { field: "_id", cellClass: "grid-btn", headerName: "Create Lead", width: 150, sortable: false, filter: false, cellRenderer: download => <CreateLeadButton rowData={download} /> },
  ]);

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const gridConfig = {
    ref: gridRef,
    rowData: data,
    columnDefs: columnDefs,
    defaultColDef: defaultColDef,
    enableCellTextSelection: true,
  };

  return (
    <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
      <div class="w-[200px] py-2">
        <PrimaryButton classes="bg-gray-800" onClick={onBtnExport}>
          Export CSV
        </PrimaryButton>
      </div>
      <AgGridReact {...gridConfig}></AgGridReact>
    </div>
  );
};

export default MenuDownloadsTable;
