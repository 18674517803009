// Holds all of our constants / variables for the different actions

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_ALERT = "SET_ALERT";
export const SET_ALERT_GENERATOR = "SET_ALERT_GENERATOR";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REMOVE_ALL = "REMOVE_ALL";

export const SET_TAG = "SET_TAG";
export const SET_TAGS = "SET_TAGS";
export const RESET_TAG = "RESET_TAG";
export const DELETE_TAG = "DELETE_TAG";

export const SET_BOOKMARK = "SET_BOOKMARK";
export const SET_BOOKMARKS = "SET_BOOKMARKS";
export const RESET_BOOKMARK = "RESET_BOOKMARK";
export const DELETE_BOOKMARK = "DELETE_BOOKMARK";
export const UPDATE_BOOKMARK = "UPDATE_BOOKMARK";
export const CREATE_BOOKMARK = "CREATE_BOOKMARK";

export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const RESET_USER = "RESET_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const CREATE_USER = "CREATE_USER";
export const ADD_BOOKMARK_TO_USER = "ADD_BOOKMARK_TO_USER";
export const ADD_BOOKMARK_TO_USERS = "ADD_BOOKMARK_TO_USERS";

export const SET_LEAD = "SET_LEAD";
export const SET_LEADS = "SET_LEADS";
export const SET_LEADS_BY_DATERANGE = "SET_LEADS_BY_DATERANGE";
export const RESET_LEAD = "RESET_LEAD";
export const DELETE_LEAD = "DELETE_LEAD";
export const UPDATE_LEAD = "UPDATE_LEAD";
export const CREATE_LEAD = "CREATE_LEAD";
