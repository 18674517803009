import axios from "./axios";
import { SET_USER, SET_USERS, RESET_USER, DELETE_USER, UPDATE_USER, CREATE_USER } from "./types";
import { setAlert } from "./alert";

export const fetchUsers = filter => async dispatch => {
  try {
    const res = await axios.get("/api/users", { params: filter });
    dispatch({
      type: SET_USERS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const fetchUser = filter => async dispatch => {
  try {
    const res = await axios.get("/api/users", { params: filter });
    dispatch({
      type: SET_USER,
      payload: res.data[0],
    });
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const fetchUserById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/users/${id}`);
    dispatch({
      type: SET_USER,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const setUser = user => async dispatch => {
  dispatch({
    type: SET_USER,
    payload: user,
  });
};

export const setUsers = users => async dispatch => {
  dispatch({
    type: SET_USERS,
    payload: users,
  });
};

export const resetUser = () => async dispatch => {
  dispatch({
    type: RESET_USER,
  });
};

export const upsertUser =
  (data, showAlert = true) =>
  async dispatch => {
    try {
      const res = await axios.post(`/api/users`, data);
      const user = res.data;
      if (showAlert) dispatch(setAlert("success", "Success! The changes were successfully saved."));
      dispatch({
        type: data._id ? UPDATE_USER : CREATE_USER,
        payload: user,
      });
      return user;
    } catch (err) {
      if (err.response) dispatch(setAlert("error", err.response.data.msg));
    }
  };

export const removeBookmarkFromUser = (user, bookmark) => async dispatch => {
  // try {
  //   const res = await axios.post(`/api/users/${user._id}/remove_bookmark/${bookmark._id}`);
  //   const updatedUser = res.data;
  //   // @TODO: Need to fix this code
  //   dispatch({
  //     type: "UPDATE_USER",
  //     payload: updatedUser,
  //   });
  //   dispatch({
  //     type: "UPDATE_BOOKMARK",
  //     payload: {
  //       ...bookmark,
  //       users: bookmark.users.filter(u => u._id !== user._id),
  //     },
  //   });
  //   // dispatch(setAlert("success", `Success! The bookmark was removed from ${updatedUser.value}'s homepage`));
  //   return updatedUser;
  // } catch (err) {
  //   if (err.response) dispatch(setAlert("error", err.response.data.msg));
  // }
};

// export const upsertUsers = users => async dispatch => {
//   try {
//     let res = await Promise.all(users.map(async user => await axios.post(`/api/users`, user)));
//     let data = res.map(r => r.data);
//     console.user(res);
//     dispatch({
//       type: SET_USERS,
//       payload: data,
//     });
//     dispatch(setAlert("success", "Success! The changes were successfully saved."));
//   } catch (err) {
//     if (err.response) dispatch(setAlert("error", err.response.data.msg));
//   }
// };

export const deleteUser = id => async dispatch => {
  try {
    await axios.delete(`/api/users/${id}`);
    dispatch({
      type: DELETE_USER,
      payload: { id },
    });
    dispatch(setAlert("success", "Successfully deleted the user"));
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};
