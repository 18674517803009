import React, { useState } from "react";
import { OutlineButton } from "../../Elements";
import axios from "../../../actions/axios";

const BookmarkModalIconUpload = ({ handleChange, setPage }) => {
  const [image, setImage] = useState(null);

  const handleFileInputChange = e => {
    const files = e.target.files;
    if (!files.length) return;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = async () => {
      const base64EncodedFile = reader.result;
      setImage(base64EncodedFile);
    };
  };

  const handleFileRemove = () => {
    setTimeout(() => setImage(null), 300);
  };

  const handleSubmit = async () => {
    const res = await axios.post(`/api/cloudinary`, { data: image });
    const icon = res.data.secure_url;
    handleChange({ id: "icon", value: icon });
    setPage("form");
  };

  return (
    <div>
      <label>
        <div class="relative mt-4 h-48 w-full cursor-pointer rounded-md border-2 border-dashed border-gray-500 bg-gray-900 object-cover transition-opacity duration-200 ease-in-out">
          {!image && (
            <div class="h-full w-full">
              <input id="logo" type="file" onChange={handleFileInputChange} accept="image/png, image/jpeg" class="sr-only" />
              <div class="flex h-full w-full items-center justify-center text-sm text-gray-300">
                <div class="text-center">
                  <div>Click here to select an image to upload</div>
                  <div class="mt-1 text-xs italic text-gray-400">Square images recommended (32x32, 64x64 or 128x128)</div>
                </div>
              </div>
            </div>
          )}
          {image && (
            <div class="flex h-full w-full items-center justify-center text-sm text-gray-300">
              <div class="flex-col justify-center">
                <img class="mx-auto h-20 w-20 rounded-md" src={image} />
                <p onClick={handleFileRemove} class="mt-2 w-full cursor-pointer text-center text-xs text-gray-400 hover:text-gray-100">
                  Remove image
                </p>
              </div>
            </div>
          )}
        </div>
      </label>
      <OutlineButton onClick={handleSubmit}>Continue</OutlineButton>
    </div>
  );
};

export default BookmarkModalIconUpload;
