import React, { useState } from "react";
import BookmarkModalIconLibrary from "./BookmarkModalIconLibrary";
import BookmarkModalIconUpload from "./BookmarkModalIconUpload";
import BookmarkModalIconFromURL from "./BookmarkModalIconFromURL";

const BookmarkModalIconSelector = ({ handleChange, setPage }) => {
  const [tab, setTab] = useState("library");

  const inactiveTabClass = "w-full cursor-pointer rounded-md bg-gray-700 py-2 text-center text-white hover:bg-opacity-50";
  const activeTabClass = "w-full cursor-pointer rounded-md bg-gray-200 py-2 text-center text-gray-900";

  return (
    <div>
      <h3 class="mb-10  flex items-center justify-center text-center text-xl font-medium leading-6 text-gray-100" id="modal-headline">
        Select icon
      </h3>
      <div class="mb-2 flex items-center justify-center space-x-1 text-sm">
        <div onClick={() => setTab("library")} class={tab === "library" ? activeTabClass : inactiveTabClass}>
          Icon Library
        </div>
        <div onClick={() => setTab("upload")} class={tab === "upload" ? activeTabClass : inactiveTabClass}>
          Upload File
        </div>
        <div onClick={() => setTab("url")} class={tab === "url" ? activeTabClass : inactiveTabClass}>
          From URL
        </div>
      </div>
      {tab === "library" && <BookmarkModalIconLibrary handleChange={handleChange} setPage={setPage} />}
      {tab === "upload" && <BookmarkModalIconUpload handleChange={handleChange} setPage={setPage} />}
      {tab === "url" && <BookmarkModalIconFromURL handleChange={handleChange} setPage={setPage} />}
    </div>
  );
};

export default BookmarkModalIconSelector;
