// Root Reducer
import { combineReducers } from "redux";

const reducers = ["auth", "alert", "tag", "bookmark", "user", "lead"];

const obj = reducers.reduce((res, reducer) => {
  const r = require(`./${reducer}`);
  res[reducer] = r.default;
  return res;
}, {});

export default combineReducers(obj);
