import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import PrivateRoute from "./components/Routing/PrivateRoute";

// Alerts

import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./components/Alerts/AlertTemplate";
import AlertOptions from "./components/Alerts/AlertOptions";
import Alert from "./components/Alerts/Alert";

// Actions
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

// Redux
import { Provider } from "react-redux";
import store from "./store";

// COMPONENTS

// Auth
import Register from "./pages/Register";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import UpdatePassword from "./pages/UpdatePassword";

import { Banner } from "./components/Layout/Banner";
import Home from "./pages/Home";
import Article from "./components/Support/Article";
import Sidebar from "./pages/Sidebar";
import ComingSoon from "./pages/ComingSoon";
import MenuDownloads from "./pages/MenuDownloads";
import Leads from "./pages/Leads";
import Playground from "./pages/Playground";
import LeadMetrics from "./pages/LeadMetrics";

// Pass token from localStorage to axios header by default
if (localStorage.token) setAuthToken(localStorage.token);

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []); // need the second parameter with empty brackets otherwise the function will loop infinitely and keep running

  return (
    <Provider store={store}>
      <Router>
        <AlertProvider className="w-1/2" template={AlertTemplate} {...AlertOptions}>
          <Alert />
          <div className="min-h-screen bg-gray-900">
            {/* <Navbar /> */}
            <Banner />
            <main>
              <div className="mx-auto">
                <section class="flex">
                  <Sidebar />
                  <div class="ml-72 w-[calc(100%_-_18rem)] p-8">
                    <Routes>
                      <Route path="/" element={<Login />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/register" element={<Register />} />
                      <Route path="/forgot-password" element={<ResetPassword />} />
                      <Route path="/update-password" element={<UpdatePassword />} />
                      <Route
                        path="/home"
                        element={
                          <PrivateRoute>
                            <Home />
                          </PrivateRoute>
                        }
                      />
                      <Route path="/tags" element={<ComingSoon />} />
                      <Route
                        path="/training"
                        element={
                          <PrivateRoute>
                            <ComingSoon />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path="/tasks"
                        element={
                          <PrivateRoute>
                            <ComingSoon />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/menu-downloads"
                        element={
                          <PrivateRoute>
                            <MenuDownloads />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/leads"
                        element={
                          <PrivateRoute>
                            <Leads />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/lead-metrics"
                        element={
                          <PrivateRoute>
                            <LeadMetrics />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/bookings"
                        element={
                          <PrivateRoute>
                            <ComingSoon />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/proposals"
                        element={
                          <PrivateRoute>
                            <ComingSoon />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/coordinators"
                        element={
                          <PrivateRoute>
                            <ComingSoon />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/settings"
                        element={
                          <PrivateRoute>
                            <ComingSoon />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/ecommerce"
                        element={
                          <PrivateRoute>
                            <ComingSoon />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/mailchimp"
                        element={
                          <PrivateRoute>
                            <ComingSoon />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/veo"
                        element={
                          <PrivateRoute>
                            <ComingSoon />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/playground"
                        element={
                          <PrivateRoute>
                            <Playground />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/article/:articleId"
                        element={
                          <PrivateRoute>
                            <Article />
                          </PrivateRoute>
                        }
                      />
                    </Routes>
                  </div>
                </section>
              </div>
            </main>
          </div>
        </AlertProvider>
      </Router>
    </Provider>
  );
};

export default App;
