import React, { useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select/creatable";
import { fetchUsers, fetchUserById } from "../../actions/user";

const UserSelect = ({ value, options, autoFetch = true, fetchUsers, fetchUserById }) => {
  useEffect(() => {
    if (!options.length && autoFetch) fetchUsers();
  }, [fetchUsers, options.length]);

  const styles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      outline: "none",
      boxShadow: "none",
      borderColor: isFocused ? "#fff" : "#ccc",
      ":hover": { borderColor: "#fff" },
      borderRadius: "0.5rem",
      borderWidth: "2px",

      backgroundColor: "rgb(31 41 55)",
      fontSize: "1rem",
      color: "white",
    }),
    multiValueRemove: styles => ({
      ...styles,
      ":hover": {
        backgroundColor: "lightgray",
        color: "black",
      },
    }),
    indicatorSeparator: styles => ({ display: "none" }),
    input: styles => ({ ...styles, color: "white", fontSize: "1rem", padding: "0.25rem", paddingLeft: "0", margin: "0" }),
    valueContainer: styles => ({ ...styles, margin: "0", paddingLeft: "1rem" }),
    singleValue: styles => ({ ...styles, color: "white", fontSize: "1rem" }),
    multiValue: styles => ({ ...styles, backgroundColor: "rgb(107 114 128)", marginLeft: "0", marginRight: "0.5rem", borderRadius: "0.5rem", color: "white", fontSize: "1rem" }),
    multiValueLabel: styles => ({ ...styles, color: "white" }),
    placeholder: styles => ({ ...styles, fontSize: "1rem", color: "rgb(107 114 128)", paddingLeft: "0" }),
    option: (styles, { isFocused }) => ({
      ...styles,
      fontSize: "0.75rem",
      borderRadius: "0.25rem",
      backgroundColor: isFocused ? "#374151" : "transparent",
      ":hover": {
        backgroundColor: "#374151",
      },
    }),
    menu: styles => ({ ...styles, zIndex: "50", backgroundColor: "#111827", color: "white", borderRadius: "0.5rem", padding: "0px 4px" }),
  };

  const handleChange = (e = {}) => {
    const user = e || value;
    console.log(user);
    fetchUserById(user._id);
  };

  return <Select defaultValue={[]} value={value || {}} menuPlacement="top" placeholder="Select a user..." onChange={handleChange} options={options} styles={styles} />;
};

const mapStateToProps = state => ({
  options: state.user.users,
  value: state.user.user,
});

export default connect(mapStateToProps, { fetchUsers, fetchUserById })(UserSelect);
