import React, { useState, useMemo, useRef } from "react";
import { Input } from "../../Elements";
import axios from "../../../actions/axios";

const BookmarkModalIconLibrary = ({ handleChange, setPage }) => {
  const [query, setQuery] = useState("");
  const [icons, setIcons] = useState([]);
  const [loading, setLoading] = useState(false);

  const canvasRef = useRef(null);

  const handleQueryChange = e => {
    setQuery(e.target.value);
    fetchIcons(e.target.value);
  };

  const debounce = (fn, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  let fetchIcons = async query => {
    if (!query) return setIcons([]);
    setLoading(true);
    const res = await axios.get(`/api/gapi?term=${query}`);
    const images = res.data.images;
    setIcons(images);
    setLoading(false);
  };

  fetchIcons = useMemo(() => debounce(fetchIcons, 500), []);

  const handleIconClick = async e => {
    const i = e.currentTarget.id.replace("icon", "");
    const url = icons[i];
    const res = await axios.post(`/api/cloudinary`, { data: url });
    const icon = res.data.secure_url;
    handleChange({ id: "icon", value: icon });
    setPage("form");
  };

  return (
    <div>
      <div class="mt-6">
        <Input autoFocus={true} onChange={handleQueryChange} value={query} placeholder={"Search for an icon..."} />
      </div>
      <div class="mt-2 h-[300px]  overflow-y-scroll rounded-md border-2 border-gray-700 p-1">
        {query && icons.length === 0 && !loading && <p className="p-2 text-sm text-gray-200">No results found</p>}
        {loading && <p className="p-2 text-sm text-gray-200">Loading...</p>}
        <div class="grid grid-cols-5 items-center justify-center">
          {icons.map((icon, i) => {
            return (
              <div id={"icon" + i} onClick={handleIconClick} key={i} class="m-auto h-14 w-14 cursor-pointer rounded-md p-2 hover:bg-gray-800">
                <img src={icon} />
              </div>
            );
          })}
        </div>
        <canvas ref={canvasRef} />
      </div>
    </div>
  );
};

export default BookmarkModalIconLibrary;
