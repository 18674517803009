import React from "react";

export const Banner = ({ message, visible }) => {
  return (
    <>
      {visible && (
        <div class="relative bg-indigo-600">
          <div class="mx-auto max-w-screen-xl py-3 px-3 sm:px-6 lg:px-8">
            <div class="pr-16 sm:px-16 sm:text-center">
              <p class="font-medium text-white">
                <span class="md:hidden">{message}</span>
                <span class="hidden md:inline">{message}</span>
                <span class="block sm:ml-2 sm:inline-block"></span>
              </p>
            </div>
            <div class="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2">
              <button type="button" class="flex rounded-md p-2 transition duration-150 ease-in-out hover:bg-indigo-500 focus:bg-indigo-500 focus:outline-none" aria-label="Dismiss">
                <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
