import axios from "./axios";
import { SET_BOOKMARK, SET_BOOKMARKS, RESET_BOOKMARK, DELETE_BOOKMARK, UPDATE_BOOKMARK, CREATE_BOOKMARK } from "./types";
import { setAlert } from "./alert";

export const fetchBookmarks = filter => async dispatch => {
  try {
    const res = await axios.get("/api/bookmark", { params: filter });
    dispatch({
      type: SET_BOOKMARKS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const fetchBookmark = filter => async dispatch => {
  try {
    const res = await axios.get("/api/bookmark", { params: filter });
    dispatch({
      type: SET_BOOKMARK,
      payload: res.data[0],
    });
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const fetchBookmarkById = id => async dispatch => {
  try {
    const res = await axios.get(`/api/bookmark/${id}`);
    dispatch({
      type: SET_BOOKMARK,
      payload: res.data,
    });
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const setBookmark = bookmark => async dispatch => {
  dispatch({
    type: SET_BOOKMARK,
    payload: bookmark,
  });
};

export const setBookmarks = bookmarks => async dispatch => {
  dispatch({
    type: SET_BOOKMARKS,
    payload: bookmarks,
  });
};

export const resetBookmark = () => async dispatch => {
  dispatch({
    type: RESET_BOOKMARK,
  });
};

export const upsertBookmark = data => async (dispatch, getState) => {
  try {
    // create any new tags
    const tags = await Promise.all(
      data.tags.map(async tag => {
        if (tag._id) return tag;
        const res = await axios.post(`/api/tag`, tag);
        return res.data._id;
      })
    );

    // remove any undefined users
    data.users = data.users.filter(Boolean);

    // if "All Employees" is selected, replace it with an array of all user ids, else only include the selected users
    const selectedAllEmployees = data.users.find(user => user.value === "All Employees");
    const users = getState().user.users;
    const dashboardUser = getState().user.user;
    const signedInUser = getState().auth.user;

    // if "All Employees" is selected, replace it with an array of all user ids
    if (selectedAllEmployees) data.users = [...new Set(users.filter(user => user.value !== "All Employees").map(user => user._id))];

    // else if the user is not editing from the "All Employees" dashboard, then add all the users selected and automatically add the dashboard user as well
    else if (dashboardUser.value !== "All Employees") data.users = [...new Set([...data.users.map(user => user._id), dashboardUser._id])];
    // otherwise, the signedIn user is creating the bookmark on the "All Employees" dashboard and we should include the user that is creating the bookmark
    else data.users = [...new Set([...data.users.map(user => user._id), signedInUser._id])];

    const res = await axios.post(`/api/bookmark`, { ...data, tags });
    const bookmark = res.data;

    dispatch(setAlert("success", "Success! The changes were successfully saved."));
    dispatch({
      type: data._id ? UPDATE_BOOKMARK : CREATE_BOOKMARK,
      payload: bookmark,
    });

    return bookmark;
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
    return { error: JSON.stringify(err.stack) };
  }
};

export const upsertBookmarks = bookmarks => async dispatch => {
  try {
    let res = await Promise.all(bookmarks.map(async bookmark => await axios.post(`/api/bookmark`, bookmark)));
    let data = res.map(r => r.data);
    console.bookmark(res);
    dispatch({
      type: SET_BOOKMARKS,
      payload: data,
    });
    dispatch(setAlert("success", "Success! The changes were successfully saved."));
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};

export const deleteBookmark = id => async dispatch => {
  try {
    await axios.delete(`/api/bookmark/${id}`);
    dispatch({
      type: DELETE_BOOKMARK,
      payload: { id },
    });
    dispatch(setAlert("success", "Successfully deleted the bookmark"));
  } catch (err) {
    if (err.response) dispatch(setAlert("error", err.response.data.msg));
  }
};
