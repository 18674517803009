import React, { useState } from "react";

const ComingSoon = () => {
  return (
    <div class="flex h-screen w-full items-center justify-center">
      <h1 class="text-3xl text-white">Coming Soon</h1>
    </div>
  );
};

export default ComingSoon;
