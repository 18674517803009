import React from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../actions/auth";
import UserSelect from "../UserSelect/UserSelect";

const Navbar = ({ logout, user, currentUser }) => {
  let navigate = useNavigate();

  const handleSignOut = () => {
    logout();
    navigate("/login");
  };

  const { account_type, first_name, last_name, value } = currentUser;

  return (
    <div className="md:flex md:items-center md:justify-between">
      <div className="min-w-[300px] text-2xl font-bold leading-none text-white sm:text-3xl">
        {account_type === "Admin" || value === "All Employees" ? (
          <UserSelect />
        ) : (
          <div class="rounded-md bg-gray-800 py-3 pl-5 text-base">
            {first_name} {last_name}
          </div>
        )}
      </div>
      <div className="mt-4 flex md:mt-0 md:ml-4">
        {account_type === "Admin" && (
          <Link to="/register" type="button" className="ml-3 inline-flex items-center rounded-md border border-transparent bg-gray-900 px-4 py-2 text-sm font-medium text-gray-400 shadow-sm transition  hover:bg-gray-800 hover:text-gray-100 focus:outline-none">
            + New User
          </Link>
        )}
        <button type="button" onClick={handleSignOut} className="ml-3 inline-flex items-center rounded-md border border-transparent bg-gray-900 px-4 py-2 text-sm font-medium text-gray-400 shadow-sm transition  hover:bg-gray-800 hover:text-gray-100 focus:outline-none">
          Sign out
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user.user,
  currentUser: state.auth.user,
});

export default connect(mapStateToProps, { logout })(Navbar);
