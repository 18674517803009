import React, { useState } from "react";
import { connect } from "react-redux";
import { updatePassword, logout } from "../actions/auth";
import { useNavigate } from "react-router-dom";

const UpdatePassword = ({ updatePassword, logout }) => {
  const [state, setState] = useState({ passwordUpdated: false, error: null });

  const handleSubmit = async e => {
    e.preventDefault();

    if (state.password === state.password2) {
      updatePassword(state.password);
      setState({
        ...state,
        error: null,
        passwordUpdated: true,
      });
    } else {
      setState({
        ...state,
        error: "The passwords entered do not match. Please try again.",
      });
    }
  };

  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  let navigate = useNavigate();

  const handleSignOut = () => {
    logout();
    navigate("/login");
  };

  return (
    <div class="relative flex flex-col sm:px-6 md:py-12 lg:px-8">
      <div class="z-10 mt-24 sm:mx-auto sm:mt-40 sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
          <div class="flex items-center justify-center">
            <div class="w-full max-w-md">
              <h2 class="mb-4 mt-8 text-center text-3xl font-extrabold leading-9 text-gray-900">Update your password</h2>
              {!state.passwordUpdated && (
                <div>
                  <div class="mt-3 text-center text-gray-500">Please enter a new password for your account. Make sure it is at least 6 characters and includes a number and lowercase letter.</div>
                  <form class="mt-8" onSubmit={handleSubmit} method="POST">
                    <div class="rounded-md shadow-sm">
                      <div>
                        <input aria-label="Password" name="password" type="password" required class="focus:shadow-outline-blue relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5" placeholder="Enter a password" onChange={handleChange} value={state.password || ""} />
                      </div>
                    </div>

                    <div class="mt-2 rounded-md shadow-sm">
                      <div>
                        <input aria-label="Password" name="password2" type="password" required class="focus:shadow-outline-blue relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5" placeholder="Confirm the password" onChange={handleChange} value={state.password2 || ""} />
                      </div>
                    </div>

                    {state.error && <div class="mt-4 text-center text-sm text-red-600">{state.error}</div>}

                    <div class="mt-6">
                      <button type="submit" class="group focus:shadow-outline-indigo relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:outline-none active:bg-indigo-700">
                        Update password
                      </button>
                    </div>
                  </form>
                  <p class="mt-6 block text-center text-sm font-medium leading-5 text-gray-700"></p>
                </div>
              )}
              {state.passwordUpdated && (
                <div>
                  <div class="mt-8 text-center text-gray-800">All set! Your password has been successfully updated. Thank you</div>
                  <div onClick={handleSignOut} class="group focus:shadow-outline-indigo relative mt-8 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:outline-none active:bg-indigo-700">
                    Sign in with new password
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { updatePassword, logout })(UpdatePassword);
