import React, { useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
const _ = require("lodash");

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    // legend: {
    //   position: "top",
    //   labels: {
    //     color: "white",
    //   },
    // },
    title: {
      display: true,
      text: "Turned Down Reasons",
      color: "white",
    },
  },
  scales: {
    x: {
      ticks: { color: "white" },
      grid: { color: "transparent" },
    },
    y: {
      ticks: { color: "white" },
      grid: { color: "#333" },
    },
  },
};

function LeadTurnedDownReasonChart({ leads }) {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(_.countBy(leads, "turned_down_reason"));
  }, [leads]);

  const chartData = {
    datasets: [
      {
        label: "Period",
        data: data,
        backgroundColor: "rgba(255, 99, 132, 0.8)",
        color: "#fff",
      },
    ],
  };

  return <Bar options={options} data={chartData} />;
}

export default LeadTurnedDownReasonChart;
