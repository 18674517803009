import React from "react";
import SampleBar from "./SampleBar";

const ChartNumberOfDownloadsByMenu = React.forwardRef(({ style, className, key, ...props }, ref) => {
  return (
    <div style={{ ...style }} className={className} ref={ref}>
      <h1 className="my-drag-handle">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </h1>
      <SampleBar {...props} />
    </div>
  );
});

export default ChartNumberOfDownloadsByMenu;
