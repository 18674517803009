import cheerio from "cheerio";
import moment from "moment";

const taskMap = {
  Call: "Call",
  Email: "Email",
  "Follow Up Call": "Call",
  "Follow Up Email": "Email",
  Meeting: "Meeting",
  "Cold Call": "Call",
  "Initial Outreach": "Call",
};

export async function getTaskDetails(taskId, leadId) {
  const res = await this.bget("/tasks/get_modal_form?id=" + taskId);
  console.log(res);
  const $ = cheerio.load(res.html);
  const user = JSON.parse(
    $('[data-cy="assignee_input"] script')
      .html()
      .match(/prePopulate: \[(.*)\]/)[1]
  );
  const type = $('#task_task_type_id option[selected="selected"]').text();
  const description = $("#task_body").text();
  let completed_at = $("#task_completed_at").val();
  completed_at = completed_at && new Date(completed_at);
  return { id: taskId, lead: leadId, user, type, description, completed_at };
}

export async function getCompletedTasks(leadId) {
  const res = await this.bget(`/tasks/get_tasks_paginated?object_id=${leadId}&object_type=Lead&type=completed`);
  const $ = cheerio.load(res.html);
  const tasks = [];
  const taskRows = $(".task_row").toArray();
  for (const el of taskRows) {
    const taskId = $(el).prop("id").replace("task_", "");
    const task = await this.getTaskDetails(taskId, leadId);
    console.log(task);
    if (task.type !== "Unassigned") tasks.push(task);
  }
  return tasks;
}

export async function getCompletedTasksAsCommunications(leadId) {
  const tasks = await this.getCompletedTasks(leadId);
  const communications = tasks.map(task => {
    return {
      id: task.id,
      date: task.completed_at,
      type: "task",
      method: taskMap[task.type],
      user: task.user.name,
    };
  });
  return communications;
}

export async function createLeadTask(leadId, assigneeId, task_type, due_date, completed, body) {
  const taskTypeMap = { Unassigned: "", Call: "14615", Email: "16966", "Follow Up Call": "14617", "Follow Up Email": "35475", Meeting: "16967", Detailing: "14616", "Cold Call": "22502", "Initial Outreach": "35476" };

  const data = {
    task: {
      token_assignee_ids: assigneeId,
      due_date: due_date,
      task_type_id: taskTypeMap[task_type],
      priority: "",
      body: body,
      taskable_type: "Lead",
      taskable_id: leadId,
    },
    associate: "on",
  };
  if (completed) {
    data.completed = "";
    data.task.completed_at = moment().utc();
  }
  const res = await this.bpost("/tasks", data);
  return res.task_id;
}
